<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="18" style="display: flex;align-items: center;">
            <a href="/"><img src="@/assets/img/logo.png" alt="logo"></a>
            <span class="creator-title">创作中心</span>
          </el-col>
          <el-col :span="6" style="display: flex; justify-content: flex-end; align-items: center;">
            <el-dropdown placement="bottom" trigger="click" v-if="user!=null">
              <div style="display: flex; align-items: center; cursor: default;margin: 10px;">
                <img :src="user.avatar" alt="" style="width: 40px; height: 40px; margin: 0 5px;border-radius: 50%"/>
                <span style="font-size: 17px; height: 40px; line-height: 40px; margin: 0 5px;" class="hidden-xs-only"> {{
                    user.nickname
                  }} </span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleToPeople">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="handleToCreator">创作中心</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-header>
      <div style="height: 60px;"></div>
      <el-main>
        <el-aside :class="windowWidth <= 768 ?'aside' : ''">
          <div style="padding: 5px;">
            <el-button type="primary" @click="handleToWrite">写文章</el-button>
          </div>
          <el-menu
              router
              background-color="#ffffff"
              text-color="rgba(0, 0, 0, 0.8)"
              active-text-color="#fff"
              :default-active="$route.meta.activeMenu ? $route.meta.activeMenu:$route.path"
              :collapse="windowWidth <= 768 ? true : false"
              style="border: none">
            <el-menu-item index="/creator/home">
              <i class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-s-menu"></i>
                <span>管理</span>
              </template>
              <el-menu-item index="/creator/blog">内容管理</el-menu-item>
              <el-menu-item index="/creator/comment">评论管理</el-menu-item>
              <el-menu-item index="/creator/column">专栏管理</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <div :style="{minWidth : (windowWidth <= 768 ? '64px': '200px')}"></div>
        <div class="main">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      user: null,
      windowWidth: 0,
    }
  },
  created() {
    this.user = localStorage.getItem("xh") ? JSON.parse(localStorage.getItem("xh")) : null;
    if (this.user == null){
      this.$message.warning('请先登录')
      this.$router.push('/')
      return
    }
  },
  mounted() {
    document.title = '创作中心 - XiaohuaBlog';
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    handleToPeople() {
      if (this.user.id == null) {
        this.$message.error('请先登录')
        return
      }
      this.$router.push('/people/' + this.user.username)
    },
    handleToCreator(){
      if (this.user == null) {
        this.$message.error('请先登录')
        return
      }
      this.$router.push('/creator')
    },
    logout() {
      localStorage.removeItem('xh')
      window.location.reload()
      this.$router.push('/')
    },
    handleToWrite() {
      if (this.user!=null) {
        this.$router.push('/write')
      } else {
        this.$message.warning('请先登录')
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
</script>

<style scoped>
.el-header {
  width: 100%;
  padding: 0 10vw;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
  position: fixed;
  top: 0;
  z-index: 999;
}

.el-header img {
  height: 60px;
}

.creator-title {
  font-size: 20px;
  font-weight: bolder;
  margin-left: 5px;
}

::v-deep.el-main {
  min-height: calc(100vh - 60px);
  background-color: #F6F6F6;
  color: #333;
  padding: 10px 10vw;
  display: flex;
}

.el-aside {
  flex: 1;
  max-width: 200px !important;
  height: calc(100vh - 80px);
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  user-select: none;
  position: fixed;
  top: 70px;
  z-index: 999;
}

.el-aside .el-button {
  width: 100%;
  margin: 10px 0;
  height: 40px;
  padding: 5px;
}

.aside {
  max-width: 64px !important;
}

.el-menu {
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

::v-deep .el-submenu__title,
.el-menu-item {
  width: calc(100% - 8px) !important;
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 8px !important;
  margin: 4px;
  padding: 0 16px !important;
  transition: color 0.3s;
}

.el-submenu .el-menu-item {
  min-width: 0 !important;
  transition: color 0.3s;
}

::v-deep .el-menu--inline {
  background-color: #fafafa !important;
}

.el-menu--inline .el-menu-item {
  background-color: #fafafa !important;
  padding-left: 49px !important;
}

.el-menu-item,
.el-menu-item i,
::v-deep .el-submenu__title,
::v-deep .el-submenu__title i {
  color: rgba(0, 0, 0, 0.88);
}

.el-menu-item:hover,
::v-deep .el-submenu__title:hover {
  background-color: #F0F0F0 !important;
}

.el-menu-item.is-active {
  color: #1890ff !important;
  background-color: #e6f4ff !important;
}

.el-menu-item.is-active i {
  color: #1890ff !important;
}

::v-deep .el-submenu.is-active .el-submenu__title,
.el-submenu.is-active .el-submenu__title i {
  color: #1890ff !important;
  background-color: #e6f4ff !important;
}

::v-deep .el-menu-item .el-tooltip {
  padding: 0 16px !important;
}

::v-deep .el-menu--vertical {
  border-radius: 10px;
}

.main {
  flex: 1;
  margin-left: 10px;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .el-header, .el-main {
    padding-left: 5px;
    padding-right: 5px;
  }

  .main {
    margin-left: 5px;
  }

  .creator-title {
    font-size: 18px;
  }

  .hidden-xs-only {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .creator-title {
    font-size: 16px;
  }
}
</style>