<template>
<div>
  <el-card :body-style="{ padding: '10px' }">
    <div slot="header">标签云</div>
    <div>
      <el-tag
        v-for="tag in tags"
        :key="tag.tagId"
        type="info"
        >
        {{ tag.tagName }}

        <span class="dot">{{ tag.count }}</span>
      </el-tag>
    </div>
  </el-card>
</div>
</template>

<script>
export default {
  name: "tagList",
  data() {
    return {
      tags: []
    }
  },
  mounted() {
    this.getTagCount()
  },
  methods: {
    getTagCount(){
      this.$request.get('/article/tagcount').then(res => {
        if (res.code == '200') {
          this.tags = res.data
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-tag {
  margin: 5px;
  padding: 0 4px;
}

.dot{
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  margin: 3px 0 3px 5px;
  padding: 0 2px;
  font-size: 12px;
  color: #ffffff;
  background-color: #8c939d;
}
</style>