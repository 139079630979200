<template>
  <div>
    <el-card style="margin-bottom: 8px;">
      {{ user.nickname }},{{ getTime() }}！ 快来创作吧！
    </el-card>
    <el-row :gutter="10">
      <el-col :span="18" :xs="24" :sm="24" :md="24" :lg="18">
        <el-card>
          <el-row :gutter="10" class="blog-data">
            <el-col :span="6" :xs="12">
              <div>
                <p>
                  <animate-number from="1" :to="articleCountYesterday" :key="articleCountYesterday"/>
                </p>
                <p>昨日新增文章数</p>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div>
                <p>
                  <animate-number from="1" :to="articleCountToday" :key="articleCountToday"/>
                </p>
                <p>今日新增文章数</p>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div>
                <p>
                  <animate-number from="1" :to="commentCountYesterday" :key="commentCountYesterday"/>
                </p>
                <p>昨日新增评论数</p>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div>
                <p>
                  <animate-number from="1" :to="commentCountToday" :key="commentCountToday"/>
                </p>
                <p>今日新增评论数</p>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div>
                <p>
                  <animate-number from="1" :to="articleCount" :key="articleCount"/>
                </p>
                <p>文章总数</p>
              </div>
            </el-col>
            <el-col :span="6" :xs="12">
              <div>
                <p>
                  <animate-number from="1" :to="commentCount" :key="commentCount"/>
                </p>
                <p>评论总数</p>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="6" :lg="6" class="hidden-md-and-down">
        <calendar></calendar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import calendar from "@/components/home/calendar.vue";
import 'element-ui/lib/theme-chalk/display.css';

export default {
  name: "home",
  components: {
    calendar,
  },
  data() {
    return {
      user: null,
      articleCountYesterday: 0,
      articleCountToday: 0,
      commentCountYesterday: 0,
      commentCountToday: 0,
      articleCount: 0,
      commentCount: 0,
    }
  },
  created() {
    this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : {};
  },
  mounted() {
    this.getarticleCountYesterday();
    this.getcommentCountYesterday();
    this.getarticleCountToday();
    this.getcommentCountToday();
    this.getarticleCount();
    this.getcommentCount();
  },
  methods: {
    getarticleCountYesterday() {
      this.$request.get('/article/count/' + this.user.id + '/1').then(res => {
        if (res.code == '200') {
          this.articleCountYesterday = res.data;
        }
      })
    },
    getcommentCountYesterday() {
      this.$request.get('/comment/countComment/' + this.user.id + '/1').then(res => {
        if (res.code == '200') {
          this.commentCountYesterday = res.data;
        }
      })
    },
    getarticleCountToday() {
      this.$request.get('/article/count/' + this.user.id + '/0').then(res => {
        if (res.code == '200') {
          this.articleCountToday = res.data;
        }
      })
    },
    getcommentCountToday() {
      this.$request.get('/comment/countComment/' + this.user.id + '/0').then(res => {
        if (res.code == '200') {
          this.commentCountToday = res.data;
        }
      })
    },
    getarticleCount() {
      this.$request.get('/article/count/' + this.user.id + '/2').then(res => {
        if (res.code == '200') {
          this.articleCount = res.data;
        }
      })
    },
    getcommentCount() {
      this.$request.get('/comment/countComment/' + this.user.id + '/2').then(res => {
        if (res.code == '200') {
          this.commentCount = res.data;
        }
      })
    },
    getTime() {
      let hour = new Date().getHours();
      if (hour < 6) {
        return '凌晨好';
      } else if (hour < 9) {
        return '早上好';
      } else if (hour < 12) {
        return '上午好';
      } else if (hour < 14) {
        return '中午好';
      } else if (hour < 17) {
        return '下午好';
      } else {
        return '晚上好';
      }
    }
  }
}
</script>

<style scoped>
.blog-data .el-col {
  margin: 5px 0;
}

.blog-data .el-col div p:first-child {
  font-size: 24px;
  font-weight: bolder;
}

.blog-data .el-col div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f1f2f3;
  padding: 10px;
  border-radius: 5px;
}

.blog-data .el-col div p {
  line-height: 24px;
  margin: 5px;
  user-select: none;
}
</style>