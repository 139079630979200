import {IEditorConfig, IToolbarConfig} from "@wangeditor/editor";
import request from "@/utils/request";

const baseUrl = process.env.VUE_APP_BASEURL;

/**
 * 编辑器配置对象
 */
export const editorConfig = {
    placeholder: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;请输入内容...',
    scroll: false, // 禁止编辑器滚动
    hoverbarKeys: {
        formula: {
            menuKeys: ['editFormula'], // “编辑公式”菜单项
        },
        attachment: {
            menuKeys: ['downloadAttachment'], // “下载附件”菜单项
        },
        link: {
            menuKeys: [
                'editLink', 'unLink', 'viewLink', // 默认菜单项，可以通过 `editor.getConfig().hoverbarKeys.link` 获取
                'convertToLinkCard' // 新增的“转为链接卡片”菜单项
            ],
        },
        linkCard: {
            // macth(editor, n){
            //     return n.nodeName === 'A' && n.getAttribute('data-link-card');
            // },
        },
    },
    MENU_CONF: {
        // “转为链接卡片”菜单项的配置
        convertToLinkCard: {
            // 自定义获取 link-card 信息的函数，返回值为一个包含 title 和 iconImgSrc 的对象
            async getLinkCardInfo(linkText, linkUrl) {
                //异步获取link-card信息
                const res = await request.get(`${baseUrl}/api/link-card`, {
                    params: {
                        url: linkUrl
                    }
                });
                const data = JSON.parse(res.data);
                const title = data.title;
                const iconImgSrc = data.iconImgSrc;
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve({
                            title,
                            iconImgSrc
                        });
                    }, 100);
                });
            }
        },
        insertLink: {
            // 使用自定义的链接校验函数
            customCheckFn: customCheckLinkFn,
            // 使用自定义的链接转换函数
            customParseUrlFn: customParseLinkUrl
        },
        editLink: {
            // 使用自定义的链接校验函数
            customCheckFn: customCheckLinkFn,
            // 使用自定义的链接转换函数
            customParseUrlFn: customParseLinkUrl
        },
        uploadImage: {
            server: `${baseUrl}/file/editor/upload`,
            fieldName: 'file',
            maxFileSize: 10 * 1024 * 1024,// 10M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
                type: "img"
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
                token: JSON.parse(localStorage.getItem("xh") || '{}').token,
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 10 秒
            timeout: 30 * 1000, // 30 秒
            // 小于该值就插入 base64 格式（而不上传），默认为 0
            base64LimitSize: 10 * 1024, // 5kb
            // 上传之前触发
            onBeforeUpload(file) {    // JS 语法
                // file 选中的文件，格式如 { key: file }
                return file
                // 可以 return
                // 1. return file 或者 new 一个 file ，接下来将上传
                // 2. return false ，不上传这个 file
            },

            // 上传进度的回调函数
            onProgress(progress) {       // JS 语法
                // progress 是 0-100 的数字
                //console.log('progress', progress)
            },
            // 单个文件上传成功之后
            onSuccess(file, res) {          // JS 语法
                //console.log(`${file.name} 上传成功`, res)
            },
            // 单个文件上传失败
            onFailed(file, res) {           // JS 语法
                //console.log(`${file.name} 上传失败`, res)
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {               // JS 语法
                console.log(`${file.name} 上传出错`, err, res)
            }
        },
        // 插入图片
        insertImage: {
            onInsertedImage(imageNode) {// JS 语法
                if (imageNode == null) return
                const {src, alt, url, href} = imageNode
                //console.log('inserted image', src, alt, url, href)
            },
            checkImage: customCheckImageFn, // 也支持 async 函数
            parseImageSrc: customParseImageSrc, // 也支持 async 函数
        },
        // 编辑图片
        editImage: {
            onUpdatedImage(imageNode) {// JS 语法
                if (imageNode == null) return

                const {src, alt, url} = imageNode
                //console.log('updated image', src, alt, url)
            },
            checkImage: customCheckImageFn, // 也支持 async 函数
            parseImageSrc: customParseImageSrc, // 也支持 async 函数
        },
        uploadVideo: {
            server: `${baseUrl}/file/editor/upload`,
            // form-data fieldName ，默认值 'wangeditor-uploaded-video'
            fieldName: 'file',
            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 50 * 1024 * 1024, // 50M
            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 3,
            // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['video/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
                type: "video",
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
                token: JSON.parse(localStorage.getItem("xh") || '{}').token,
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 30 秒
            timeout: 15 * 1000, // 15 秒
            // 视频不支持 base64 格式插入
            // 上传之前触发
            onBeforeUpload(file) {      // JS 语法
                // file 选中的文件，格式如 { key: file }
                return file

                // 可以 return
                // 1. return file 或者 new 一个 file ，接下来将上传
                // 2. return false ，不上传这个 file
            },
            // 上传进度的回调函数
            onProgress(progress) {       // JS 语法
                // progress 是 0-100 的数字
                //console.log('progress', progress)
            },
            // 单个文件上传成功之后
            onSuccess(file, res) {          // JS 语法
                //console.log(`${file.name} 上传成功`, res)
            },
            // 单个文件上传失败
            onFailed(file, res) {          // JS 语法
                //console.log(`${file.name} 上传失败`, res)
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {               // JS 语法
                //console.log(`${file.name} 上传出错`, err, res)
            },
        },
        insertVideo: {
            onInsertedVideo(videoNode) {// JS 语法
                if (videoNode == null) return
                const {src} = videoNode
                //console.log('inserted video', src)
            },
            checkVideo: customCheckVideoFn, // 也支持 async 函数
            parseVideoSrc: customParseVideoSrc, // 也支持 async 函数
        },
        // “上传附件”菜单的配置
        uploadAttachment: {
            server: `${baseUrl}/file/editor/upload`, // 服务端地址
            timeout: 30 * 1000, // 30s超时
            fieldName: 'file',
            meta: {
                type: "attachment",
            }, // 请求时附加的数据
            metaWithUrl: false, // meta 拼接到 url 上
            headers: {
                token: JSON.parse(localStorage.getItem("xh") || '{}').token,
            }, // 自定义 header
            maxFileSize: 10 * 1024 * 1024, // 10M

            onBeforeUpload(file) {
                //console.log('onBeforeUpload', file)
                return file // 上传 file 文件
                // return false // 会阻止上传
            },
            onProgress(progress) {
                //console.log('onProgress', progress)
            },
            // 单个文件上传成功之后
            onSuccess(file, res) {          // JS 语法
                //console.log(`${file.name} 上传成功`, res)
            },
            // 单个文件上传失败
            onFailed(file, res) {          // JS 语法
                //console.log(`${file.name} 上传失败`, res)
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {               // JS 语法
                //console.log(`${file.name} 上传出错`, err, res)
            },

            // // 上传成功后，用户自定义插入文件
            // customInsert(res: any, file: File, insertFn: Function) {
            //   console.log('customInsert', res)
            //   const { url } = res.data || {}
            //   if (!url) throw new Error(`url is empty`)

            //   // 插入附件到编辑器
            //   insertFn(`customInsert-${file.name}`, url)
            // },

            // // 用户自定义上传
            // customUpload(file: File, insertFn: Function) {
            //   console.log('customUpload', file)

            //   return new Promise(resolve => {
            //     // 插入一个文件，模拟异步
            //     setTimeout(() => {
            //       const src = `https://www.w3school.com.cn/i/movie.ogg`
            //       insertFn(`customUpload-${file.name}`, src)
            //       resolve('ok')
            //     }, 500)
            //   })
            // },

            // // 自定义选择
            // customBrowseAndUpload(insertFn: Function) {
            //   alert('自定义选择文件，如弹出图床')
            //   // 自己上传文件
            //   // 上传之后用 insertFn(fileName, link) 插入到编辑器
            // },

            // 插入到编辑器后的回调
            onInsertedAttachment(elem) {
                //console.log('inserted attachment', elem)
            },
        }
    }
};


export const toolbarConfig = {
    insertKeys: {
        index: 22, // 自定义插入的位置
        keys: [
            'insertFormula', // “插入公式”菜单
            'editFormula',// “编辑公式”菜单
            'uploadAttachment',// “上传附件”菜单
        ],
    },
    excludeKeys: ['fullScreen']
};

/**
 * 自定义校验链接函数
 *
 * @param {string} text - 链接文本
 * @param {string} url - 链接 URL
 * @returns {string|undefined|boolean} - 如果校验通过，返回 true；如果校验不通过，返回错误信息字符串；如果没有返回值，表示校验不通过且不提示任何信息
 */
function customCheckLinkFn(text, url) {
    if (!url) {
        return; // 如果 URL 为 falsy 值，直接返回
    }
    if (url.indexOf('http') !== 0) {
        return '链接必须以 http/https 开头'; // 如果 URL 不是以 http/https 开头，返回错误信息
    }
    return true; // 校验通过
}

/**
 * 自定义转换链接 URL 函数
 *
 * @param {string} url - 要转换的链接 URL
 * @returns {string} - 转换后的链接 URL，如果原始 URL 不是以 http/https 开头，则添加 http:// 前缀
 */
function customParseLinkUrl(url) {
    if (url.indexOf('http') !== 0) {
        return `http://${url}`; // 如果 URL 不是以 http/https 开头，添加 http:// 前缀
    }
    return url; // 返回原始 URL
}

// 自定义校验图片
function customCheckImageFn(src, alt, url) {
    if (!src) {
        return
    }
    if (src.indexOf('http') !== 0) {
        return '图片网址必须以 http/https 开头'
    }
    return true

    // 返回值有三种选择：
    // 1. 返回 true ，说明检查通过，编辑器将正常插入图片
    // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
    // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
}

// 转换图片链接
function customParseImageSrc(src) {
    if (src.indexOf('http') !== 0) {
        return `http://${src}`
    }
    return src
}

// 自定义校验视频
function customCheckVideoFn(src, poster) {// JS 语法
    if (!src) {
        return
    }
    if (src.indexOf('http') !== 0) {
        return '视频地址必须以 http/https 开头'
    }
    return true

    // 返回值有三种选择：
    // 1. 返回 true ，说明检查通过，编辑器将正常插入视频
    // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
    // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
}

// 自定义转换视频
function customParseVideoSrc(src) {// JS 语法
    if (src.includes('.bilibili.com')) {
        // 转换 bilibili url 为 iframe （仅作为示例，不保证代码正确和完整
        const arr = src.split('/')
        const vid = arr[arr.length - 1]
        //console.log(vid)
        return `<iframe src="//player.bilibili.com/${vid}" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>`
    }
    return src
}