<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        友情链接
      </div>
      <div>
        <template v-for="item in blogroll">
          <el-link :href="item.linkUrl" target="_blank" type="info">{{ item.linkName }}</el-link>
        </template>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "blogroll",
  data() {
    return {
      blogroll:[]
    }
  },
  mounted() {
    this.loadBlogroll()
  },
  methods: {
    loadBlogroll() {
      this.$request.get('/blogroll/list').then(res => {
        if(res.code === '200'){
          this.blogroll = res.data
        }else {
          this.$message.error(res.msg)
          this.blogroll = [{}]
        }
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-card__body {
  padding: 10px;
}

::v-deep.el-link {
  margin: 5px 10px;
}

::v-deep.el-link:hover {
  text-decoration: none;
}
</style>