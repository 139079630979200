<template>
  <div>
    <div class="comment-line-box" v-for="childComment in childComments">
      <div class="comment-list-item">
        <el-link :href="'/people/'+childComment.createByUsername" target="_blank" :underline="false">
          <el-avatar :src="childComment.userAvatar" icon="el-icon-user-solid" :size="28" style=""></el-avatar>
        </el-link>
        <div class="right-box">
          <div class="new-info-box clearfix">
            <div class="comment-top">
              <p class="comment-name">
                <el-link :href="'/people/'+childComment.createByUsername" target="_blank" :underline="false">
                  {{ childComment.createByName }}
                </el-link>
                <el-tag size="mini" v-show="childComment.createBy == article.createBy"
                        style="margin-left: 5px;">作者
                </el-tag>
              </p>
              <p class="text" v-if="childComment.pid!=childComment.rootPid">回复</p>
              <p class="reply-name" v-if="childComment.pid!=childComment.rootPid">
                <el-link :href="'/people/'+parentComment.createByUsername" target="_blank" :underline="false">
                  {{ parentComment.createByName }}
                </el-link>
                <el-tag size="mini" v-show="parentComment.createBy == article.createBy"
                        style="margin-left: 5px;">作者
                </el-tag>
              </p>
            </div>
            <div class="comment-center">
              <div class="new-comment" v-html="childComment.content"></div>
            </div>
            <div class="opt-comment">
              <p class="date">{{ formatDate(childComment.createTime) }}</p>
              <p :class="'reply-comment' + (isReplyVisible(childComment.id) ? ' active' : '')">
                <i class="el-icon-chat-dot-square"></i>
                <span
                    @click="toggleReply(childComment.id)">{{
                    isReplyVisible(childComment.id) ? '取消回复' : '回复'
                  }}</span>
              </p>
              <p class="delete" v-if="childComment.createBy === user.id">
                <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more el-icon--left"></i>
                </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="deleteComment(childComment.id)">
                      <i class="el-icon-delete"></i>删除
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </p>
            </div>
          </div>
          <!-- 回复框 -->
          <reply v-if="isReplyVisible(childComment.id)" :rootPid="rootPid" :comment="childComment"
                 :showReplay="showReplay"
                 :blogId="blogId" :getComments="getComments" style="margin-top: 5px;"></reply>
        </div>
      </div>
      <!-- 嵌套递归 -->
      <child :childComments="childComment.children" :parentComment="childComment" :article="article" :rootPid="rootPid"
             :show-reply-id="showReplyId" @toggle-reply="toggleReply"
             :getComments="getComments"></child>
    </div>
  </div>
</template>

<script>
import reply from "@/components/comment/reply.vue";

export default {
  name: "child",
  components: {
    reply
  },
  props: {
    childComments: {
      type: Array,
      default: null
    },
    parentComment: {
      type: Object,
      default: null
    },
    article: {
      type: Object,
      default: null
    },
    rootPid: {
      type: Number,
      default: null
    },
    getComments: {
      type: Function,
      default: null
    },
    showReplyId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      user: {},
      showReplay: false,     // 是否显示评论回复框
      blogId: null,     // 博客ID
    }
  },
  mounted() {
    this.user = localStorage.getItem("xh") ? JSON.parse(localStorage.getItem("xh")) : {};
    this.blogId = this.article.id;
  },
  methods: {
    deleteComment(id) {
      this.$confirm('删除评论会将回复您的评论一并删除，确定删除该评论吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$request.delete('/comment/delete', {
          params: {
            cid: id
          }
        }).then(res => {
          if (res.code == '200') {
            this.$message.success('删除成功')
            this.getComments(this.article.id)
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 关闭评论回复输入框
    toggleReply(commentId) {
      this.$emit('toggle-reply', commentId);
    },
    isReplyVisible(commentId) {
      return this.showReplyId === commentId;
    },
    formatDate(time) {
      const now = new Date();
      const pastDate = new Date(time);
      const timeDiffInSeconds = Math.floor((now.getTime() - pastDate.getTime()) / 1000);
      if (timeDiffInSeconds < 60) {
        return '刚刚';
      } else if (timeDiffInSeconds < 60 * 60) {
        const minutes = Math.floor(timeDiffInSeconds / 60);
        return `${minutes}分钟前`;
      } else if (timeDiffInSeconds < 24 * 60 * 60 && now.getDate() === pastDate.getDate()) {
        const hours = Math.floor(timeDiffInSeconds / (60 * 60));
        return `${hours}小时前`;
      } else if (now.getDate() - pastDate.getDate() === 1) {
        const hours = pastDate.getHours().toString().padStart(2, '0');
        const minutes = pastDate.getMinutes().toString().padStart(2, '0');
        return `昨天${hours}:${minutes}`;
      } else if (timeDiffInSeconds < 8 * 24 * 60 * 60) {
        const days = Math.floor(timeDiffInSeconds / (24 * 60 * 60));
        return `${days}天前`;
      } else if (now.getFullYear() === pastDate.getFullYear()) {
        const month = pastDate.getMonth() + 1;
        const day = pastDate.getDate();
        return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      } else {
        const year = pastDate.getFullYear();
        const month = pastDate.getMonth() + 1;
        const day = pastDate.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
      }
    }
  }
}
</script>

<style scoped>
.comment-list-item {
  display: flex;
  padding-bottom: 16px;
}

.comment-list-item .el-link {
  align-items: unset;
}

.comment-list-item .right-box {
  flex: 1;
  margin-left: 10px;
}

.comment-list-item .right-box .comment-top {
  display: flex;
  align-items: center;
  user-select: none;
}

.comment-list-item .right-box .comment-top .el-link {
  color: #606266;
  text-decoration: none;
}

.comment-list-item .right-box .comment-top .el-tag {
  padding: 0 1px;
}

.comment-list-item .right-box .comment-top .text {
  font-size: 14px;
  margin: 0 5px;
}

.comment-list-item .right-box .comment-center {
  margin: 5px 0;
}

.comment-list-item .right-box .new-comment {
  line-height: 24px;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.comment-list-item .right-box .opt-comment {
  display: flex;
  align-items: center;
  color: #8c939d;
}

.comment-list-item .right-box .opt-comment .reply-comment {
  font-size: 15px;
  padding: 0 14px;
  cursor: pointer;
}

.comment-list-item .right-box .opt-comment .reply-comment.active {
  color: #409eff;
}

.comment-list-item .right-box .opt-comment .date,
.comment-list-item .right-box .opt-comment .reply-comment span {
  font-size: 14px;
  user-select: none;
}

.comment-list-item .right-box .opt-comment .reply-comment:hover,
.comment-list-item .right-box .opt-comment .delete:hover {
  color: #409eff;
}

.comment-list-item .right-box .opt-comment .delete {
  margin-left: auto;
  cursor: pointer;
}
</style>