<template>
  <div class="search">
    <div class="search-toolbar">
      <div class="search-input">
        <el-input v-model="search" placeholder="输入你想搜索内容的关键词"></el-input>
        <el-button type="primary" @click="handleQuery">搜索</el-button>
      </div>
    </div>
    <el-card>
      <Index :list="article"></Index>
      <el-empty :description="'抱歉，没有找到“'+keywords+'”相关内容'" v-if="article.length === 0"></el-empty>
      <!--    分页-->
      <el-pagination
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
          :current-page="pageNum"
          :page-size="pageSize"
          :page-sizes="pageSizeList"
          layout="prev, pager, next, jumper">
      </el-pagination>
    </el-card>
    <copyright style="margin-top: 5px;text-align: center;"/>
  </div>
</template>

<script>
import Index from "@/components/home/index.vue";
import copyright from "@/components/copyright.vue";
import Calendar from "@/components/home/calendar.vue";

export default {
  name: "search",
  components: {Calendar, copyright, Index},
  data() {
    return {
      search: '',
      keywords: '',
      pageNum: 1,
      pageSize: 20,
      pageSizeList: [10, 20, 50, 100],
      article: []
    }
  },
  created() {
    this.search = this.$route.query.keywords;
    this.keywords = this.$route.query.keywords;
  },
  mounted() {
    this.handleSearch(this.keywords)
    document.title = this.keywords + '- XiaohuaBlog搜索'
  },
  methods: {
    // 点击搜索按钮
    handleQuery() {
      if (this.search) {
        this.$router.push({name: 'search', query: {keywords: this.search}})
        this.keywords = this.search
        this.handleSearch(this.search)
      }
    },
    handleSearch(keyword) {
      if (keyword == null || keyword == '') return
      document.title = keyword + '- XiaohuaBlog搜索'
      this.$request.get('article/search', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          //keyword空格转%
          keyword: keyword.replace(/\s+/g, '%')
        }
      }).then(res => {
        if (res.code == "200") {
          this.article = res.data.records
        }
      })
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum
      this.handleSearch(this.search)
    },
  },
}
</script>

<style scoped>
::v-deep .search-toolbar {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
}

::v-deep .search-toolbar .search-input {
  height: 36px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .search-input .el-input {
  max-width: 540px !important;
}

::v-deep .search-input .el-input__inner {
  max-width: 540px;
  height: 34px;
  font-size: 14px;
  border-right: none;
  border-radius: 5px 0 0 5px;
}

::v-deep .search-input .el-button {
  height: 34px;
  font-size: 14px;
  border: 1px solid #409EFF;
  border-radius: 0 5px 5px 0;
}

::v-deep .search-input .el-button:hover {
  border: 1px solid #66b1ff;
}
</style>