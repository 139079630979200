<template>
  <div class="page-404">
    <img :src="require('@/assets/img/404/'+RandomImg)" alt="">
    <div class="container">
      <h1>404</h1>
      <p>抱歉，您访问的页面不存在</p>
      <p>请检查您输入的网址是否正确，或者点击下方按钮返回首页</p>
      <router-link to="/">
        <el-button>返回首页</el-button>
      </router-link>
    </div>
  </div>
  <!-- /.page-404 -->
</template>

<script>
export default {
  name: "Page-404",
  data() {
    return {
      RandomImg: '',
      RandomImgArr: ['1.png', '2.png', '3.png', '4.png', '5.png', '6.png', '7.png', '8.png', '9.png', '10.png', '11.png', '12.png', '13.png', '14.png', '15.png', '16.png', '17.png', '18.png', '19.png', '20.png', '21.png', '22.png', '23.png', '24.png', '25.png', '26.png', '27.png', '28.png', '29.png', '30.png', '31.png', '32.png']
    }
  },
  created() {
    const index = Math.floor(Math.random() * this.RandomImgArr.length)
    //console.log(index)
    this.RandomImg = this.RandomImgArr[index]
    //console.log(this.RandomImg)
    document.title = '404 - 页面不存在'
  }
}
</script>

<style scoped>
.page-404 {
  width: 100%;
  height: calc(100vh - 100px);
  background: #f1f2f3;
  font-size: 16px;
  color: #888;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  user-select: none;
}

.page-404 img {
  width: 50%;
}

.page-404 .container {
  text-align: center;
}

.page-404 .container h1 {
  font-family: "Oswald";
  font-size: 140px;
  line-height: 160px;
  color: #444;
  margin-bottom: 20px;
  letter-spacing: 5px;
}

.page-404 .container p {
  font-size: 18px;
  color: #444;
  margin: 20px 0;
}

/*给el-button设置样式*/
.page-404 .container .el-button {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  color: #fff;
  background: #409eff;
  border: none;
}

.page-404 .container .el-button:hover {
  background: #67c23a;
}

/*适配*/
@media screen and (max-width: 768px) {
  .page-404 {
    flex-direction: column;
  }
  .page-404 .container h1 {
    font-size: 80px;
    line-height: 90px;
  }
}

@media screen and (max-width: 480px) {
  .page-404 .container h1 {
    font-size: 60px;
    line-height: 70px;
  }
}
</style>