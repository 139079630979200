<template>
  <div>
    <el-card class="creator">
      <h2 @click="handleToCreator"><i class="el-icon-creator"></i>创作中心</h2>
      <div style="justify-content: space-around;">
        <p>
          <el-button type="primary" plain circle @click="handleToWrite"><i class="el-icon-fawenzhang"></i></el-button>
          <br/>写文章
        </p>
        <p>
          <el-button type="warning" plain circle @click="handleToDynamic"><i class="el-icon-fadongtai"></i></el-button>
          <br/>发动态
        </p>
        <p>
          <el-button type="info" plain circle @click="handleToRecycle"><i class="el-icon-caogaoxiang"></i></el-button>
          <br/>草稿箱
        </p>
      </div>
      <div class="data" v-if="user!=null">
        <div>
          <p>今日发布数</p>
          <p>
            <animate-number from="1" :to="entryRod" :key="entryRod"/>
          </p>
          <p>昨日数据
            <animate-number from="1" :to="entryRodYesterday" :key="entryRodYesterday"/>
          </p>
        </div>
        <div>
          <p>今日新增评论数</p>
          <p>
            <animate-number from="1" :to="commentCount" :key="commentCount"/>
          </p>
          <p>昨日数据
            <animate-number from="1" :to="commentCountYesterday" :key="commentCountYesterday"/>
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "creator",
  data() {
    return {
      user: null,
      entryRod: 0,//今日发布数
      entryRodYesterday: 0,//昨日数据
      commentCount: 0,//今日新增浏览量
      commentCountYesterday: 0,//昨日数据
      rodNumber: null,
    }
  },
  mounted() {
    this.user = localStorage.getItem("xh") ? JSON.parse(localStorage.getItem("xh")) : null;
    if (this.user!=null) {
      this.getEntryRod();
      this.getEntryRodYesterday();
      this.getCommentCount();
      this.getCommentCountYesterday();
    }
  },
  methods: {
    handleToCreator(){
      if (this.user!=null) {
        this.$router.push('/creator')
      } else {
        this.$message.warning('请先登录')
      }
    },
    handleToWrite() {
      if (this.user!=null) {
        this.$router.push('/write')
      } else {
        this.$message.warning('请先登录')
      }
    },
    handleToDynamic() {
      this.$router.push('/dynamic')
    },
    handleToRecycle() {
      if (this.user!=null) {
        this.$router.push({name: 'ArticleManage', params: {active: 4}});
      } else {
        this.$message.warning('请先登录')
      }

    },
    //获取今日发布数
    getEntryRod() {
      this.$request.get('/article/count/' + this.user.id + '/0').then(res => {
        if (res.code == '200') {
          this.entryRod = res.data;
        }else if (res.code == '401'){
          this.$router.push('/')
        }
      })
    },
    //获取昨日发布数
    getEntryRodYesterday() {
      this.$request.get('/article/count/' + this.user.id + '/1').then(res => {
        if (res.code == '200') {
          this.entryRodYesterday = res.data;
        }
      })
    },
    //获取今日新增评论量
    getCommentCount() {
      this.$request.get('/comment/countComment/' + this.user.id + '/0').then(res => {
        if (res.code == '200') {
          this.commentCount = res.data;
        }
      })
    },
    //获取昨日新增评论
    getCommentCountYesterday() {
      this.$request.get('/comment/countComment/' + this.user.id + '/1').then(res => {
        if (res.code == '200') {
          this.commentCountYesterday = res.data;
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.rodNumber); // 在组件销毁前清除定时器
  }
  ,
}
</script>

<style scoped>
.creator div {
  display: flex;
  flex-direction: row;
}

.creator h2 {
  font-size: 16px;
  margin-bottom: 10px;
  padding-left: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.creator h2 i {
  font-size: 24px;
  margin-right: 5px;
}

.creator p {
  text-align: center;
  font-size: 12px;
}

.creator .el-button {
  font-size: 20px;
  margin: 5px 0;
}

.el-button--primary.is-plain {
  border-color: #ecf5ff;
}

.el-button--warning.is-plain {
  border-color: #fdf6ec;
}

.el-button--info.is-plain {
  border-color: #f4f4f5;
}

.creator .data {
  padding: 10px;
  background: rgba(132, 145, 165, 0.05);
  margin-top: 10px;
}

.creator .data div:first-child {
  border-right: 1px solid #e6e6e6;
}

.creator .data div {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.creator .data div p:first-child,
.creator .data div p:last-child {
  color: #999;
}

.creator .data div p:nth-child(2) {
  font-size: 22px;
  font-weight: bold;
  margin: 5px 0;
}

.creator .data div p:last-child span {
  color: #333;
  font-weight: bold;
  font-size: 14px;
}

</style>