<template>
  <div>
    <el-calendar v-model="value" style="--el-calendar-border: none !important">
    </el-calendar>
  </div>
</template>
<script>
export default {
  name: 'Calendar',
  data() {
    return {
      value: new Date()
    }
  },
  mounted() {
    let prev = document.querySelector('.el-calendar__button-group > .el-button-group > button:nth-child(1)');
    let today = document.querySelector('.el-calendar__button-group > .el-button-group > button:nth-child(2)');
    let next = document.querySelector('.el-calendar__button-group > .el-button-group > button:nth-child(3)');
    prev.innerHTML = '<';
    today.innerHTML = '今';
    next.innerHTML = '>';
    prev.style.padding = '7px 10px';
    today.style.padding = '7px 10px';
    next.style.padding = '7px 10px';
  },
  methods: {}
}
</script>
<style scoped>
::v-deep .el-calendar-table {
  border-collapse: separate;
  border-spacing: 3px;
}

::v-deep .el-calendar-table thead {
  height: 30px;
}

::v-deep .el-calendar-table th {
  padding: 0;

}

::v-deep .el-calendar-table td {
  border: none !important;
  text-align: center;
}

::v-deep .el-calendar-table .el-calendar-day {
  height: auto;
  padding: 0;
  height: 30px;
  line-height: 30px;
}

::v-deep .el-calendar__body {
  padding: 5px;
}

::v-deep .is-today .el-calendar-day {
  background-color: #409EFF;
  color: #FFFFFF;
}

::v-deep .is-today .el-calendar-day:hover {
  background-color: #409EFF;
  color: #FFFFFF;
}
</style>
