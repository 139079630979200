<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="12">
            <a href="/"><img src="@/assets/img/logo.png" alt="logo"></a>
          </el-col>
          <el-col :span="12" style="display: flex; justify-content: flex-end; align-items: center;">
            <el-dropdown placement="bottom" trigger="click" v-if="user!=null">
              <div style="display: flex; align-items: center; cursor: default;margin: 10px;">
                <img :src="user.avatar" alt="" style="width: 40px; height: 40px; margin: 0 5px;border-radius: 50%"/>
                <span style="font-size: 17px; height: 40px; line-height: 40px; margin: 0 5px;" class="hidden-xs-only"> {{
                    user.nickname
                  }} </span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleToPeople">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="handleToCreator">创作中心</el-dropdown-item>
                <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="toolbar" ref="piediv" :class="{is_fixed: is_fixed}">
          <Toolbar
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"/>
        </div>
        <div class="toolbar-fixed"></div>
        <div class="editor">
          <div class="titleInput">
            <el-input
                type="textarea"
                :rows="1"
                autosize
                placeholder="请输入标题(5 ~ 100字)"
                minlength="5"
                maxlength="100"
                show-word-limit
                @input="handleEnter"
                @change="onChange"
                v-model="title">
            </el-input>
          </div>
          <Editor
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
              @onChange="onChange"
          />
          <div class="pub-setting">
            <p id="pub-setting">发布设置</p>
            <el-form :model="articleConfig" label-width="80px" :rules="rules" ref="formRef">
              <el-form-item label="文章标签" prop="tag" required>
                <el-cascader
                    :options="options"
                    :props="{ multiple: true, checkStrictly: true,emitPath:false}"
                    clearable
                    :show-all-levels="false"
                    :popper-class="'popper-select'"
                    v-model="articleConfig.tag"
                    @change="tagChange">
                  <template slot-scope="{ node,data }">
                    {{ data.label }}
                    <span
                        v-if="articleConfig.tag.length >= 7"
                        class="exceeded-overlay"
                        @click="handleSelectionExceeded(node)"
                    ></span>
                  </template>
                </el-cascader>
              </el-form-item>
              <el-form-item label="文章专栏">
                <el-select
                    v-model="articleConfig.categoryId"
                    placeholder="请选择"
                    clearable
                    class="select-new-tag">
                  <el-option
                      v-for="item in columnList"
                      :key="item.id"
                      :label="item.tagName"
                      :value="item.id">
                  </el-option>
                </el-select>
                <el-button @click="columnDialogVisible=true" class="select-new-tag" icon="el-icon-plus">添加专栏</el-button>
              </el-form-item>
              <el-form-item label="添加封面" style="margin-bottom: 26px;">
                <div style="display: flex;align-items: center;position: relative;">
                  <el-upload
                      class="avatar-uploader"
                      :action="this.$baseUrl+'/file/upload'"
                      accept="image/*"
                      :headers="{token : user.token}"
                      :show-file-list="false"
                      :limit="1"
                      :file-list="fileList"
                      :on-success="handleAvatarSuccess"
                      :on-remove="handleRemove"
                      :before-upload="beforeAvatarUpload">
                    <div v-if="articleConfig.thumbnail" class="avatar">
                      <img :src="articleConfig.thumbnail" class="avatar"/>
                      <div class="mask">
                        <div title="预览" @click.stop="handlePictureCardPreview()">
                          <i class="el-icon-zoom-in"></i>
                        </div>
                        <div style="margin-left:30px;" title="移除" @click.stop="removeImage()">
                          <i class="el-icon-delete"></i>
                        </div>
                      </div>
                    </div>
                    <div v-else class="avatar-uploader-icon">
                      <div>
                        <i class="el-icon-plus"></i>
                        <small style="margin-left: 8px;">添加文章封面</small>
                      </div>
                      <small>仅支持jpg、png、jpeg格式</small>
                    </div>
                  </el-upload>
                  <small class="imgTips">如果未上传图片，则以选择的第一个标签+随机颜色背景为文章封面</small>
                </div>
              </el-form-item>
              <el-form-item label="文章摘要">
                <div class="summary">
                  <el-input
                      type="textarea"
                      :rows="2"
                      :autosize="{ minRows: 2, maxRows: 4}"
                      placeholder="请输入文章摘要(5 ~ 200字)"
                      minlength="5"
                      maxlength="200"
                      show-word-limit
                      class="input-new-tag"
                      @input="handleEnter"
                      v-model="articleConfig.summary">
                  </el-input>
                  <el-button round plain class="extract" @click="getSummary">一键提取</el-button>
                </div>
              </el-form-item>
              <el-form-item label="是否原创">
                <el-radio-group v-model="articleConfig.isOriginal">
                  <el-radio :label="'1'">
                    原创
                    <el-tooltip content="该网站为个人网站，暂无法保护原创版权，谨慎发布！" placement="top">
                      <i class="el-icon-question" style="color: #8c939d"></i>
                    </el-tooltip>
                  </el-radio>
                  <el-radio :label="'0'">
                    转载
                    <el-tooltip content="转载文章请填写原文链接,并确认获得原文作者许可" placement="top">
                      <i class="el-icon-question" style="color: #8c939d"></i>
                    </el-tooltip>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="文章来源" v-if="articleConfig.isOriginal=='0'" prop="source">
                <el-input v-model="articleConfig.source" class="source"></el-input>
              </el-form-item>
              <el-form-item label="是否公开">
                <el-radio-group v-model="articleConfig.isPublic">
                  <el-radio :label="'1'">
                    公开
                    <el-tooltip content="所有人可见" placement="top">
                      <i class="el-icon-question" style="color: #8c939d"></i>
                    </el-tooltip>
                  </el-radio>
                  <el-radio :label="'0'">
                    私密
                    <el-tooltip content="仅自己可见" placement="top">
                      <i class="el-icon-question" style="color: #8c939d"></i>
                    </el-tooltip>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <el-dialog :visible.sync="dialogVisible" style="text-align: center;">
              <img width="300px" :src="articleConfig.thumbnail" alt="">
            </el-dialog>
          </div>
        </div>
        <div class="clear"></div>
      </el-main>
      <el-footer>
        <div class="footer">
          <!-- 点击按钮顶部/发文设置-->
          <div>
            <el-button size="medium" plain icon="el-icon-arrow-up" class="skip" @click="handleUp()" v-if="upordown">
              返回顶部
            </el-button>
            <el-button size="medium" plain icon="el-icon-arrow-down" class="skip" @click="handleDown()" v-else>
              发布设置
            </el-button>
            <span class="contentWatch">字数：{{ contentLength }}</span>
          </div>
          <div>
            <span class="contentWatch">{{ contentStatus }}</span>
            <el-button size="medium" type="info" @click="handlePreview()" plain class="preview" :disabled="valid">预览
            </el-button>
            <el-button size="medium" type="primary" @click="handlePublish()" class="publish" :disabled="valid">发布
            </el-button>
          </div>
        </div>
      </el-footer>
      <div class="footer-fiexd"></div>
    </el-container>
    <column-dialog :user="user" :dialogFormVisible="columnDialogVisible" @get-column="getColumn"
                   @handle-close-dialog="handleCloseDialog"></column-dialog>
  </div>
</template>
<script>
import columnDialog from "@/components/creator/columnDialog.vue";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import Prism from 'prismjs'
import 'prismjs/themes/prism-one-dark.css'
import {editorConfig, toolbarConfig} from '@/assets/js/editor/Config'
import {Boot} from '@wangeditor/editor'
import markdownModule from '@wangeditor/plugin-md'
import formulaModule from '@wangeditor/plugin-formula'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
import linkCardModule from '@wangeditor/plugin-link-card'

[markdownModule, formulaModule, attachmentModule, linkCardModule].forEach(module => Boot.registerModule(module))
export default {
  name: 'Write',
  components: {Editor, Toolbar, columnDialog},
  data() {
    return {
      user: {},
      editor: null,
      title: '',
      html: '',
      toolbarConfig: toolbarConfig,
      editorConfig: editorConfig,
      mode: 'default', // or 'simple'
      is_fixed: false,
      articleConfig: {
        title: '',
        content: '',
        tag: [],
        categoryId: null,
        summary: '',
        isOriginal: '1',
        isPublic: '1',
      },
      options: [],
      columnList: [],
      columnDialogVisible: false,
      fileList: [],
      dialogVisible: false,
      observer: null,
      upordown: false,
      contentLength: 0,
      contentStatus: "",
      valid: true,
      timer: null,
      rules: {
        tag: [
          {required: true, message: '请选择文章标签', trigger: 'change'},
          {type: 'array', min: 1, max: 7, message: '最多选择7个标签', trigger: 'change'}
        ],
        source: [
          {required: toolbarConfig.isOriginal == 0 ? false : true, message: '请输入文章来源', trigger: 'blur'},
          {type: 'url', message: '请输入正确的文章来源,仅支持url链接', trigger: 'blur'},
        ]
      },
    }
  },
  mounted() {
    this.user = localStorage.getItem("xh") ? JSON.parse(localStorage.getItem("xh")) : {};
    document.title = "写文章 - XiaohuaBlog"
    if (this.user.id == null) {
      this.$router.push({name: 'home'})
      this.$message.warning('请先登录')
      return
    }
    if (this.user.id == null && this.$route.params.id !== undefined) {
      this.$router.push({name: 'detail', params: {id: this.$route.params.id}})
    }
    this.getArticle(this.$route.params.id)
    this.getTagofArticle()
    this.getColumn()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('beforeunload', this.onBeforeUnload);
    window.addEventListener('unload', this.onUnload);// 添加 popstate 事件监听
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', this.onPopState);
    this.initObserver()
    //获取路由参数，查询文章
  },
  methods: {
    handleToPeople() {
      if (this.user.id == null) {
        this.$message.error('请先登录')
        return
      }
      this.$router.push('/people/' + this.user.username)
    },
    handleToCreator() {
      if (this.user == null) {
        this.$message.error('请先登录')
        return
      }
      this.$router.push('/creator')
    },
    logout() {
      localStorage.removeItem('xh')
      window.location.reload()
      this.$router.push('/')
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      Prism.highlightAll();
    },
    onChange() {
      Prism.highlightAll();
      if (this.articleConfig.content != this.editor.getHtml() && this.editor.getText().length != 0 || this.articleConfig.title != this.title) {
        this.contentStatus = "草稿保存中";
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.articleConfig.content = this.editor.getHtml()
          this.contentLength = this.editor.getText().length
          this.articleConfig.title = this.title;
          if (this.title.length > 0) {
            document.title = this.title + " - XiaohuaBlog"
          }
          this.save()
        }, 1000)
      } else {
        this.contentLength = this.editor.getText().length
      }
      this.valid = true;
      if (this.title.length >= 5 && this.editor.getText().length >= 10) {
        this.valid = false
      }
    },
    getArticle(id) {
      if (id == undefined) {
        return
      }
      this.$request.get("/article/findById/" + id).then(res => {
        if (res.code == "200") {
          this.articleConfig = res.data
          this.title = res.data.title
          this.html = res.data.content
          if (!Array.isArray(this.articleConfig.tag)) {
            this.articleConfig.tag = this.articleConfig.tag.trim().length === 0 ? [] : this.articleConfig.tag.split(",");
          }
          document.title = this.title + " - XiaohuaBlog"
        } else if (res.code == "403") {
          this.$router.push({name: 'detail', params: {id: id}})
        }
      })
    },
    getTagofArticle() {
      this.$request.get("/category/selectAll").then(res => {
        if (res.code == "200") {
          this.options = res.data.map(item => {
            return {
              value: item.id,
              label: item.name,
              children: item.children.map(child => {
                return {
                  value: child.id,
                  label: child.name
                }
              })
            }
          })
        }
      })
    },
    getColumn() {
      this.$request.get("/customtag/getTagById").then(res => {
        if (res.code == "200") {
          this.columnList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleSelectionExceeded(node) {
      if (node.data.children) return
      this.$message.info('最多只支持选择7项')
    },
    tagChange(value) {
      this.articleConfig.tag = value
    },
    handleAvatarSuccess(response, file, fileList) {
      // 把user的头像属性换成上传的图片的链接
      this.articleConfig.thumbnail = response.data
      this.fileList = fileList;
      //console.log(response.data)
    },
    handleRemove(file, fileList) {
      this.articleConfig.thumbnail = ''
      this.fileList = fileList;
    },
    beforeAvatarUpload(file) {
      const isJPG =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、jpeg格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    handlePictureCardPreview() {
      this.dialogVisible = true;
    },
    removeImage() {
      this.articleConfig.thumbnail = ''
      this.fileList = []
    },
    handleCloseDialog(flag) {
      this.columnDialogVisible = flag;
    },
    //一键提取文章内容前200个字符
    getSummary() {
      const editor = this.editor;
      //如果编辑器为空或去除空格和换行符为空，则直接返回
      if (editor == null || editor.getText().replace(/\s/g, '').length <= 0) {
        this.$message.info('文章内容为空，无法提取')
      }
      const text = editor.getText().substring(0, 200);
      this.articleConfig.summary = text;
    },
    //保存
    save() {
      if (this.articleConfig.tag instanceof Array) {
        this.articleConfig.tag = this.articleConfig.tag.toString()
      }
      if (this.$route.params.id !== undefined) {
        this.$request.put('/article/update', this.articleConfig).then(res => {
          if (res.code == '200') {
            this.contentStatus = '草稿已保存'
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$request.post('/article/add', this.articleConfig).then(res => {
          if (res.code == '200') {
            this.contentStatus = '草稿已保存'
            this.$router.push({name: 'edit', params: {id: res.data}})
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    handlevalid() {
      if (this.title.length < 5) {
        this.$message.error('标题字数不能少于5个字！');
        return false;
      }
      if (this.articleConfig.content.length < 10) {
        this.$message.error('内容字数不能少于10个字！');
        return false;
      }
      return true;
    },
    //预览
    handlePreview() {
      if (!this.handlevalid()) return
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.save()
          setTimeout(() => {
            this.$router.push({name: 'detail', params: {id: this.articleConfig.id}})
          }, 100)
        } else {
          this.handleDown();
        }
      })
    },
    //发布
    handlePublish() {
      if (!this.handlevalid()) return
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.articleConfig.status = '1';
          this.articleConfig.audit = '2';
          this.save()
          setTimeout(() => {
            this.$router.push({name: 'detail', params: {id: this.articleConfig.id}})
          }, 100)
        } else {
          this.handleDown();
        }
      })
    },
    handleUp() {
      window.scrollTo({top: 60, behavior: "smooth"});
      //延时器
      setTimeout(() => {
        document.documentElement.scrollTop = 0;
      }, 200);
    },
    handleDown() {
      document.querySelector(".pub-setting").scrollIntoView({behavior: "smooth"})
    },
    //返回顶部/发布设置
    //监听发布设置与视窗交汇
    initObserver() {
      // 创建IntersectionObserver实例
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0.2) {
          this.upordown = false;
        } else {
          this.upordown = true;
        }
      }, {
        threshold: [0.2, 1],
      });

      // 开始观察元素
      this.observer.observe(document.querySelector('.pub-setting'));
    },
    //阻止换行事件
    handleEnter() {
      // 检查输入值中是否含有换行符
      if (this.title.includes('\n') || this.articleConfig.summary.includes('\n')) {
        // 如果包含，移除输入值中的换行符
        this.title = this.title.replace(/\n/g, '');
        this.articleConfig.summary = this.articleConfig.summary.replace(/\n/g, '');
      }
    },
    //获取编辑器配置
    getEditorConfig() {
      console.log(this.editor.getConfig())
      console.log(this.editor.getMenuConfig('codeSelectLang').codeLangs)
    },
    //监视标签距离页面顶部距离
    handleScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //获取监听指定区域滚动位置的值
      const toolbar = document.querySelector(".toolbar"); //获取固定元素初始滚动位置
      const offsetTop = toolbar.offsetTop;
      const toolbarHeight = toolbar.offsetHeight;
      const toolbarFixed = document.querySelector(".toolbar-fixed");
      if (scrollTop > offsetTop) {
        this.is_fixed = true;
        toolbarFixed.style.height = toolbarHeight + 'px';
      } else {
        toolbarFixed.style.height = 0 + 'px';
        this.is_fixed = false;
      }
      // console.log("toolbar：" + offsetTop);
      // console.log("视窗：" + scrollTop);
      // console.log("toolbarh:" + toolbarHeight);
    },
    onBeforeUnload(event) {
      const message = '您可能有未保存的更改';
      event.returnValue = message; // 标准的跨浏览器兼容性处理
      return message;
    },
    onUnload() {
    },
    onPopState(e) {
      // 监听到浏览器回退事件（这里提示用的confirm，是可以自定义的）
      if (confirm('离开当前页面数据可能会丢失，您确定离开当前页面吗？')) {
        // 点击确定回退
        window.removeEventListener('popstate', this.onPopState)
        window.history.go(-1)
      } else {
        // 点击取消不回退
        window.history.pushState(null, null, window.location.href)
      }
    },
  },
  watch: {
    //监听articleConfig.tag,判断如果变成字符串，将其转变成数组
    'articleConfig.tag': {
      handler(newVal, oldVal) {
        if (typeof newVal === 'string') {
          this.articleConfig.tag = newVal.split(',').map(item => item.trim())
        }
      }
    },
    immediate: false, // 如果设置为 true，则监听器会在初始化时立即触发一次
    deep: false // 对于监听对象内部的变化需要设置为 true，但这里我们监听的是一个具体的属性，所以设置为 false
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
    if (this.observer) {
      this.observer.disconnect(); // 停止观察
      this.observer = null; // 清除引用
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    window.removeEventListener('unload', this.onUnload);
    window.removeEventListener('popstate', this.onPopState);
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.el-header {
  width: 100%;
  padding: 0 10vw;
  background-color: #FFFFFF;
}

.el-header img {
  height: 60px;
}

.el-main {
  height: 100%;
  padding: 0;
  overflow: visible;
  background-color: #F5F5F5;
}

.toolbar-fixed {
  width: 100%;
}

.is_fixed {
  position: fixed;
  top: 0;
  z-index: 5;
}

.toolbar {
  width: 100%;
  padding: 10px 0;
  background-color: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.04);
}

::v-deep .w-e-bar {
  width: fit-content;
  margin: 0 auto;
  align-items: center;
}

::v-deep .w-e-bar-divider {
  width: 1px;
  height: 25px;
}

/*::v-deep .w-e-bar-item {
  *z-index: 3;
*}
*/

::v-deep .w-e-bar-item button {
  border-radius: 5px;
}

.editor {
  width: 840px;
  margin: 0 auto 20px;
  padding-top: 20px;
}

.editor .titleInput {
  padding: 20px 20px 0;
  background-color: #FFFFFF;
}

.editor .titleInput ::v-deep .el-textarea__inner {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  resize: none;
  padding-bottom: 15px;
  border: 0;
  border-radius: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.editor .pub-setting {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.04);
  margin-top: 10px;
}

::v-deep .w-e-text-container {
  min-height: 800px;
  padding: 0 20px;
}

::v-deep .w-e-text-container .w-e-scroll {
  padding-bottom: 20px;
}

::v-deep .w-e-text-container .w-e-scroll #w-e-textarea-1 {
  min-height: 500px;
  position: relative;
  /*z-index: 1;
  */
}

/*::v-deep .w-e-text-container .w-e-bar {
  *z-index: 2;
*}
 */

::v-deep .w-e-text-container h1, ::v-deep .w-e-text-container h2, ::v-deep .w-e-text-container h3, ::v-deep .w-e-text-container h4, ::v-deep .w-e-text-container h5 {
  font-weight: bold;
}

::v-deep.w-e-text-container h1 {
  font-size: 24px;
}

::v-deep.w-e-text-container h2 {
  font-size: 22px;
}

::v-deep.w-e-text-container h3 {
  font-size: 20px;
}

::v-deep.w-e-text-container h4 {
  font-size: 18px;
}

::v-deep.w-e-text-container h5 {
  font-size: 16px;
}

div.w-e-textarea-link-card-container {
  width: 450px;
  margin: 0 auto;
  background-color: #f1f1f1;
  border-radius: 10px;
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
}

div.w-e-textarea-link-card-text-container {
  flex: 1;
  padding-right: 10px;
}

div.w-e-textarea-link-card-text-container p {
  margin-top: 5px;
  font-weight: bold;
}

div.w-e-textarea-link-card-text-container span {
  opacity: .5;
}

div.w-e-textarea-link-card-icon-container {
  width: 76px;
  overflow: hidden;
}

div.w-e-textarea-link-card-icon-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#pub-setting {
  font-weight: bold;
  margin: 0 10px 20px;
}

::v-deep .el-cascader {
  width: 340px;
  margin-left: 10px;
}

.el-tag:first-child {
  margin-left: 10px;
}

.select-new-tag {
  margin-left: 10px;
}

.input-new-tag {
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 18px;
  color: #8c939d;
  width: var(--w);
  height: var(--h);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.avatar-uploader-icon small {
  font-size: 12px;
}

.avatar {
  width: var(--w);
  height: var(--h);
  display: block;
}

.mask {
  opacity: 0;
  position: absolute;
  top: 0;
  width: var(--w);
  height: var(--h);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
}

.avatar:hover .mask {
  opacity: 1;
}

.imgTips {
  margin-left: 10px;
  position: absolute;
  bottom: -16px;
  color: #8c939d;
}

.summary, .source {
  width: 90%;
  position: relative;
}

::v-deep.summary .el-textarea__inner {
  resize: none;
  padding: 5px 10px 20px 10px;
}

::v-deep.summary .el-input__count {
  margin-right: 60px;
  margin-bottom: 3px;
  line-height: 14px;
}

::v-deep.summary .extract {
  min-height: unset !important;
  line-height: 18px;
  border: none;
  padding: 0 !important;
  position: absolute;
  right: 0px;
  bottom: 6px;
}

::v-deep.el-radio-group,
::v-deep.source .el-input__inner,
::v-deep.el-form-item--small .el-form-item__error {
  margin-left: 10px;
}

.el-footer {
  width: 100%;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  background-color: #FFFFFF;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
}

.footer-fiexd {
  width: 100%;
  height: 60px;
}

.footer {
  margin: 0 auto;
  max-width: 840px;
  height: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentWatch {
  font-size: 14px;
  color: #8C939D;
  margin: 0 20px;
}

.skip {
  border: none;
}

.skip:hover,
.skip:focus {
  color: #606266 !important;
}

.preview:hover {
  color: #969696 !important;
  border-color: #c8c8c8 !important;
  background: #f0f0f0 !important;
}

.preview:focus {
  color: #969696 !important;
  border-color: #D3D4D6 !important;
  background: #F4F4F5 !important;
}

@media screen and (max-width: 767.5px) {
  .el-header {
    padding: 0;
  }

  .hidden-xs-only {
    display: none !important;
  }

  .editor {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .imgTips {
    line-height: 12px;
  }

  ::v-deep .el-cascader {
    width: 260px;
  }

  .el-footer {
    padding: 5px;
  }

  .footer {
    padding: 0;
  }

  .skip {
    padding: 10px 8px;
  }

  .preview, .publish {
    padding: 8px 12px;
  }

  .contentWatch {
    margin: 0 10px;
  }
}

</style>
<style>
/* 文章标签--禁用一级选择框*/
.popper-select li[aria-haspopup="true"] .el-checkbox {
  display: none;
}

/* 遮罩元素-覆盖整个li元素 */
.popper-select .exceeded-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
}

/* 选中节点隐藏遮罩元素 */
.popper-select .el-checkbox.is-checked ~ .el-cascader-node__label .exceeded-overlay {
  display: none;
}
</style>