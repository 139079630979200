import { render, staticRenderFns } from "./resource.vue?vue&type=template&id=55de825a&scoped=true"
import script from "./resource.vue?vue&type=script&lang=js"
export * from "./resource.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55de825a",
  null
  
)

export default component.exports