<template>
  <div>
    dynamicManage
  </div>
</template>

<script>
export default {
  name: "dynamicManage"
}
</script>

<style scoped>

</style>