<template>
  <div>
    <el-container>
      <el-aside>
        <el-menu
            router
            background-color="#ffffff"
            text-color="rgba(0, 0, 0, 0.8)"
            active-text-color="#fff"
            :default-active="$route.path"
            :collapse="screenWidth>768?false:true"
            style="border: none">
          <el-menu-item :index="'/people/'+user.username+'/settings/base'">
            <i class="el-icon-jichushezhi"></i>
            <span slot="title">基础设置</span>
          </el-menu-item>
          <el-menu-item :index="'/people/'+user.username+'/settings/security'">
            <i class="el-icon-anquanshezhi"></i>
            <span slot="title">安全设置</span>
          </el-menu-item>
          <el-menu-item :index="'/people/'+user.username+'/settings/custom'">
            <i class="el-icon-gexinghua"></i>
            <span slot="title">个性化</span>
          </el-menu-item>
          <el-menu-item :index="'/people/'+user.username+'/settings/binding'">
            <i class="el-icon-zhanghaobangding"></i>
            <span slot="title">账号绑定</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {
      activeTab: '',
      screenWidth: 0,
      user: null,
    }
  },
  created() {
    this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : null;
  },
  mounted() {
    if (this.user === null){
      this.$router.push('/')
    }
    if (this.user.username !== this.$route.params.username){
      this.$router.push({name: 'people', params: {username: this.$route.params.username}})
    }
    this.screenWidth = document.body.clientWidth
    window.addEventListener('resize', ()=> {
      this.screenWidth = document.body.clientWidth
    })
  },
  destroyed() {
    window.removeEventListener('resize', ()=> {
      this.screenWidth = document.body.clientWidth
    })
  }
}
</script>

<style scoped>
.el-container{
  padding: 15px 0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  background-color: #FFFFFF;
}
.el-aside {
  max-width: 200px !important;
  background-color: #fff;
  padding: 0 5px;
  overflow: hidden;
  user-select: none;
  border-right: 1px solid #e6e6e6;;
}
.el-menu-item{
  height: 40px;
  line-height: 40px;
  margin: 5px 0;
  border-radius: 5px;
}
.el-menu-item:hover{
  background-color: rgba(0,0,0,0.05) !important;
}
.el-menu-item.is-active{
  color: #1687ff !important;
  background-color: rgba(22,135,255,0.1) !important;
}

.el-main {
  padding: 8px 40px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 768px) {
  .el-aside{
    max-width: 74px !important;
  }
  .el-menu-item{
    text-align: center;
  }
}
</style>