<template>
  <div>
    留言板
  </div>
</template>

<script>
export default {
  name: "board"
}
</script>

<style scoped>

</style>