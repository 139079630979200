<template>
  <div>
    <el-row :gutter="10">
      <el-col :md="18" :sm="17" :xs="24" class="main">
        <div>
          <el-carousel trigger="click" height="180px" style="border-radius: 5px;">
            <el-carousel-item v-for="item in bannerList" :key="item.id" style="background-color: #66b1ff">
              <img :src="item.bannerUrl" :alt="item.description" style="width: 100%;height: 100%;object-fit: cover;">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div style="margin-top: 10px;" v-if="windowWidth<768">
          <Creator/>
        </div>
        <el-card :body-style="{ padding: '10px' }" style="margin: 10px 0;">
          <el-menu
              router
              mode="horizontal"
              text-color="rgba(0, 0, 0)"
              active-text-color="#1687ff"
              :default-active="$route.path"
              style="border: none">
            <el-menu-item index="/hot">
              <span slot="title">热门</span>
            </el-menu-item>
            <el-menu-item index="/">
              <span slot="title">推荐</span>
            </el-menu-item>
            <el-menu-item index="/fresh">
              <span slot="title">最新</span>
            </el-menu-item>
          </el-menu>
          <el-main class="content">
            <router-view/>
          </el-main>
        </el-card>
      </el-col>
      <el-col :md="6" :sm="7" :xs="24" class="aside" v-if="windowWidth>768">
        <Creator/>
        <tag-list/>
        <blogroll/>
        <Calendar/>
        <copyright/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Creator from "@/components/home/creator.vue";
import Calendar from "@/components/home/calendar.vue";
import tagList from "@/components/home/tagList.vue";
import copyright from "@/components/copyright.vue";
import blogroll from "@/components/home/blogroll.vue";

export default {
  name: 'Home',
  components: {
    Creator, Calendar,tagList,copyright,blogroll
  },
  data() {
    return {
      //视窗宽度
      windowWidth: 0,
      bannerList: []
    }
  },
  mounted() {
    this.getBanner();
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    document.title = 'XiaohuaBlog';
  },
  methods: {
    getBanner(){
      this.$request.get("/banner/latest").then(res => {
        if(res.code == '200'){
          this.bannerList = res.data;
        }
      })
    }
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
</script>

<style scoped>
.el-main {
  padding: 0;
  overflow: visible;
}

.aside {
  display: grid;
  grid-gap: 10px;
}

.content {
  padding-top: 0.9rem;
  background-color: #FFFFFF;
}
</style>