<template>
  <div>
    <div class="comment">
      <div class="comment-input">
        <el-input
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 4}"
            :placeholder="comment==null?'善语结善缘，恶语伤人心':'回复：'+comment.createByName"
            minlength="1"
            maxlength="1000"
            @blur="handleBlur"
            @input="handleInput"
            @focus="handleFocus"
            ref="commentInput"
            :class="{ focused: isFocused }"
            v-model="commentItem.content">
        </el-input>
        <div class="action">
          <p class="count">{{ contentLength }}/1000</p>
          <el-button type="primary" size="mini" :disabled="contentLength > 0 ? false : true " @click="AddComment">评论</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reply",
  props: {
    rootPid: {
      type: Number,
      default: null
    },
    comment: {    // 父评论
      type: Object,
      default: null
    },
    blogId: {
      type: Number,
      default: null
    },
    getComments: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      user: {},
      isFocused: false,
      contentLength: 0,
      commentItem: {
        content: "",
        createBy: null,
        createByName: null,
        blogId: null,
        pid: null,
        rootPid: null
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : {};
      this.$refs.commentInput.focus()
    })
  },
  methods: {
    AddComment() {
      this.commentItem.blogId = this.blogId;
      this.commentItem.pid = this.comment.id;
      this.commentItem.rootPid = this.rootPid;
      if (this.commentItem.content.replace(/\n/g, '').length == 0) {
        this.$message.error("评论内容不能为空")
        return;
      }
      //将回车替换为<br>
      this.commentItem.content = this.commentItem.content.replace(/\n/g, '');
      this.$request.post("/comment/add", this.commentItem).then(res => {
        if (res.code == "200") {
          this.$message.success("评论成功")
          this.$parent.toggleReply(null)
          this.commentItem.content = ''
          this.contentLength = 0
          this.getComments(this.blogId)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleFocus() {
      this.isFocused = true
    },
    handleBlur() {
      this.isFocused = false
      //this.$parent.toggleReply(null)
    },
    handleInput() {
      this.commentItem.content = this.commentItem.content.replace(/\n/g, '')
      this.contentLength = this.commentItem.content.replace(/\n/g, '').length
    }
  }
}
</script>

<style scoped>
.comment {
  display: flex;
  flex-direction: row;
}

.comment-input {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #f1f2f3;
  background: #f1f2f3;
}

::v-deep .comment-input .el-textarea__inner {
  min-height: auto;
  padding: 5px 10px;
  font-size: 16px;
  color: #000c17;
  background: transparent;
  resize: none;
  border: none;
  transition: min-height 0.3s ease;
}

::v-deep .comment-input .focused .el-textarea__inner {
  /* 当聚焦时应用的样式 */
  min-height: 58px !important; /* 聚焦时的高度 */
}

::v-deep .comment-input .el-textarea__inner::placeholder {
  color: #5d5b5b;
}

::v-deep .comment-input:has(.el-textarea__inner:focus) {
  background: #ffffff;
  border: 1px solid #409eff;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 5px 10px 10px;
}

.action p {
  font-size: 13px;
  color: #8c939d;
  margin-right: 20px;
}
</style>