import Vue from 'vue'
import VueRouter from 'vue-router'
import User from '@/views/User/User.vue'
import HomeView from '@/views/User/Home.vue'
import DynamicView from '@/views/User/dynamic.vue'
import ResourceView from '@/views/User/resource.vue'
import BoardView from '@/views/User/board.vue'
import People from '@/views/people/people.vue'
import Settings from "@/views/people/manage/Settings.vue";
import Detail from '@/views/article/detail.vue'
import Write from '@/views/Creator/Write.vue'
import Search from "@/views/User/search.vue";
import Creator from "@/views/Creator/creator.vue";
import CreatorHome from "@/views/Creator/manage/home.vue";
import ContentManage from "@/views/Creator/manage/content.vue";
import CommentManage from "@/views/Creator/manage/comment.vue";
import ColumnManage from "@/views/Creator/manage/column.vue";
import ArticleManage from "@/views/Creator/manage/content/article.vue";
import DynamicManage from "@/views/Creator/manage/content/dynamicManage.vue";
import Page404 from "@/views/codeView/Page-404.vue";
import Page403 from "@/views/codeView/Page-403.vue";

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/', name: 'User', component: User, redirect: '/', children: [
            {
                path: '/', meta: {name: "首页"}, component: HomeView,
                children: [
                    {
                        path: '/',
                        name: 'home',
                        meta: {name: "推荐", activeMenu: "/"},
                        component: () => import('@/views/home/home.vue')
                    },
                    {
                        path: 'hot',
                        name: 'hot',
                        meta: {name: "热门", activeMenu: "/"},
                        component: () => import('@/views/home/hot.vue')
                    },
                    {
                        path: 'fresh',
                        name: 'fresh',
                        meta: {name: "最新", activeMenu: "/"},
                        component: () => import('@/views/home/fresh.vue')
                    }
                ]
            },
            {path: 'dynamic', name: 'dynamic', meta: {name: "动态"}, component: DynamicView},
            {path: 'resource', name: 'resource', meta: {name: "资源分享"}, component: ResourceView},
            {path: 'board', name: 'board', meta: {name: "留言板"}, component: BoardView},
            {
                path: 'people/:username',
                name: 'people',
                meta: {name: "个人中心"},
                component: People,
                redirect: {name: 'peopleblog'},
                children: [
                    {
                        path: '/',
                        name: 'peopleblog',
                        meta: {name: "个人博客文章", activeMenu: "/people/:username/"},
                        component: () => import('@/views/people/list/blog.vue')
                    },
                ]
            },

            {
                path: 'people/:username/settings', name: 'Settings', meta: {name: "个人设置"}, component: Settings,redirect: {name: 'base'},
                children: [
                    {
                        path: 'base',
                        name: 'base',
                        meta: {name: "基础设置"},
                        component: () => import('@/views/people/manage/account/Base.vue')
                    },
                    {
                        path: 'security',
                        name: 'security',
                        meta: {name: "安全设置"},
                        component: () => import('@/views/people/manage/account/Security.vue')
                    },
                    {
                        path: 'custom',
                        name: 'custom',
                        meta: {name: "个性化"},
                        component: () => import('@/views/people/manage/account/Custom.vue')
                    },
                    {
                        path: 'binding',
                        name: 'binding',
                        meta: {name: "账号绑定"},
                        component: () => import('@/views/people/manage/account/Binding.vue')
                    }
                ]
            },
            {path: 'p/:id', name: 'detail', meta: {name: "文章详情"}, component: Detail},
            {path: '/search', name: 'search', meta: {name: "搜索"}, component: Search},
            {path: 'test', meta: {name: "个人中心"}, component: () => import('@/views/User/test1.vue')},
            {path: '403', name: '403', meta: {name: "无权访问"}, component: Page403},
        ]
    },
    {
        path: '/creator',
        name: 'creator',
        meta: {name: "创作中心"},
        component: Creator,
        redirect: {name: 'creatorHome'},
        children: [
            {path: 'home', name: 'creatorHome', meta: {name: "创作中心首页"}, component: CreatorHome},
            {
                path: 'blog',
                name: 'ContentManage',
                meta: {name: "内容管理"},
                component: ContentManage,
                redirect: {name: 'ArticleManage'},
                children: [
                    {
                        path: 'article',
                        name: 'ArticleManage',
                        meta: {name: "文章管理", activeMenu: "/creator/blog"},
                        component: ArticleManage
                    },
                    {
                        path: 'dynamic',
                        name: 'DynamicManage',
                        meta: {name: "文章管理", activeMenu: "/creator/blog"},
                        component: DynamicManage
                    },
                ]
            },
            {path: 'comment', name: 'CommentManage', meta: {name: "评论管理"}, component: CommentManage},
            {path: 'column', name: 'ColumnManage', meta: {name: "专栏管理"}, component: ColumnManage},
        ]
    },
    {path: '/write', name: 'write', meta: {name: "写文章"}, component: Write},
    {path: '/p/:id/edit', name: 'edit', meta: {name: "编辑文章"}, component: Write},
    {path: '/login', name: 'login', meta: {name: "登录"}, component: () => import('@/views/login/Login.vue')},
    {path: '/register', name: 'register', meta: {name: "注册"}, component: () => import('@/views/login/Register.vue')},
    {path: '*', name: '404', component: Page404},
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next()
    } else {
        next()
    }
})

export default router