<template>
  <div>
    <el-card :body-style="{ padding: '10px' }">
      <el-menu
          router
          mode="horizontal"
          text-color="rgba(0, 0, 0)"
          active-text-color="#1687ff"
          :default-active="$route.path"
          style="border: none">
        <el-menu-item index="/creator/blog/article">
          <span slot="title">文章</span>
        </el-menu-item>
        <el-menu-item index="/creator/blog/dynamic">
          <span slot="title">动态</span>
        </el-menu-item>
      </el-menu>
      <el-main class="content">
        <router-view/>
      </el-main>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "contentManage"
}
</script>

<style scoped>
::v-deep .el-card__body {
  min-height: calc(100vh - 80px);
}

.content{
  padding: 10px;
}
</style>