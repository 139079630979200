<template>
  <div>
    <div class="blog-filter">
      <ul class="blog-filter-nav">
        <li>
          <el-button size="medium" icon="el-icon-s-order" disabled>状态</el-button>
        </li>
        <li v-for="(item,index) in nav" :key="index" @click="active=index">
          <el-button size="medium" :class="index==active?'is_active':''" @click="item.func">{{ item.name }}</el-button>
        </li>
      </ul>
    </div>
    <div class="blog-mamage">
      <template v-for="(item,index) in article">
        <div class="blog-mamage-item" :key="index">
          <div class="blog-mamage-item-title">
            <a class="item-title" :href="'/p/'+item.id+'/edit'" target="_blank">{{ item.title }}</a>
            <p class="blog-mamage-item-time">{{ formatDate(item.createTime) }}</p>
          </div>
          <div class="blog-mamage-item-type">
            <el-tag :disable-transitions="true" type="warning" size="mini" v-if="item.isOriginal==1">原创</el-tag>
            <el-tag :disable-transitions="true" type="info" size="mini" v-if="item.isOriginal==0">转载</el-tag>
            <el-tag :disable-transitions="true" type="warning" size="mini" v-if="item.status==1&item.audit==0">未通过
            </el-tag>
            <el-tag :disable-transitions="true" type="success" size="mini" v-if="item.status==1&item.audit==2">审核中
            </el-tag>
            <el-tag :disable-transitions="true" type="info" size="mini" v-if="item.status==0">草稿</el-tag>
            <el-tag :disable-transitions="true" type="info" size="mini" v-if="item.isPublic==0">仅我可见</el-tag>
            <el-tag :disable-transitions="true" type="info" size="mini" v-if="item.isComment==0">禁止评论</el-tag>
          </div>
          <div class="blog-manage-item-info">
            <div class="item-info-left">
              <p>浏览量<span>{{ item.viewCount }}</span></p>
              <p>评论<span>{{ item.commentCount ? item.commentCount : '0' }}</span></p>
            </div>
            <div class="item-info-right">
              <p><a :href="'/p/'+item.id+'/edit'">编辑</a></p>
              <p><a :href="'/p/'+item.id" target="_blank">浏览</a></p>
              <p>
                <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more el-icon--left"></i>
                </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="item.isPublic==0">
                      <el-button plain round @click="handleupdate('isPublic','1',item)">公开</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="item.isPublic==1">
                      <el-button plain round @click="handleupdate('isPublic','0',item)">私密</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="item.isComment==0">
                      <el-button plain round @click="handleupdate('isComment','1',item)">允许评论</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="item.isComment==1">
                      <el-button plain round @click="handleupdate('isComment','0',item)">禁止评论</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="active!=5">
                      <el-button plain round @click="handleupdate('delFlag','0',item)">删除</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="active==5">
                      <el-button plain round @click="handleupdate('delFlag','1',item)">回收至草稿箱</el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="active==5">
                      <el-button plain round @click="handledelete(item)">彻底删除</el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </p>
            </div>
          </div>
        </div>
      </template>
      <el-empty description="快去发布文章吧" v-if="article.length==0" style="margin-top: 100px;"></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "blog",
  data() {
    return {
      user: {},
      nav: [
        {func: this.all, name: "全部"},
        {func: this.allview, name: "全部可见"},
        {func: this.myview, name: "仅我可见"},
        {func: this.audits, name: "审核"},
        {func: this.draft, name: "草稿箱"},
        {func: this.recycle, name: "回收站"}
      ],
      active: 0,
      article: [],
      status: null,
      isOriginal: null,
      audit: null,
      unaudit: null,
      delFlag: null,
    }
  },
  created() {
    this.user = localStorage.getItem("xh") ? JSON.parse(localStorage.getItem("xh")) : {}
  },
  mounted() {
    if (this.$route.params.active) {
      this.active = this.$route.params.active
      this.draft()
    } else {
      this.all()
    }
  },
  methods: {
    all() {
      this.status = null;
      this.isPublic = null;
      this.audit = null;
      this.unaudit = null;
      this.delFlag = 1;
      this.load()
    },
    allview() {
      this.status = 1;
      this.isPublic = 1;
      this.audit = 1;
      this.unaudit = null;
      this.delFlag = 1;
      this.load()
    },
    myview() {
      this.status = 1;
      this.isPublic = 0;
      this.audit = null;
      this.unaudit = null;
      this.delFlag = 1;
      this.load()
    },
    audits() {
      this.status = 1;
      this.isPublic = null;
      this.audit = null;
      this.unaudit = null;
      this.unaudit = 1;
      this.delFlag = 1;
      this.load()
    },
    draft() {
      this.status = 0;
      this.isPublic = null;
      this.audit = null;
      this.unaudit = null;
      this.delFlag = 1;
      this.load()
    },
    recycle() {
      this.status = null;
      this.isPublic = null;
      this.audit = null;
      this.unaudit = null;
      this.delFlag = 0;
      this.load()
    },
    load() {
      this.$request.get("article/findByUserId", {
        params: {
          id: this.user.id,
          pageNum: 1,
          pageSize: 20,
          status: this.status,
          isPublic: this.isPublic,
          audit: this.audit,
          unaudit: this.unaudit,
          delFlag: this.delFlag
        }
      }).then(res => {
        if (res.code == "200") {
          this.article = res.data.records
        }
      })
    },
    handleupdate(type, value, item) {
      this.$request.put("article/updateStatus/" + type + "/" + value, item).then(res => {
        if (res.code == "200") {
          this.$message.success("操作成功")
          this.load()
        }
      })
    },
    handledelete(item) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$request.delete("article/delete", {
          data: item
        }).then(res => {
          if (res.code == "200") {
            this.$message.success("删除成功")
            this.load()
          }
        })
      }).catch(() => {
        this.$message.info("已取消删除")
      })
    },
    formatDate(time) {
      return this.$moment(time == null ? '' : time).format('YYYY-MM-DD HH:mm:ss');
    }
  },
}
</script>

<style scoped>
.blog-filter {
  display: flex;
  justify-content: space-between;
}

.blog-filter-nav {
  width: 100%;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

::v-deep .el-button {
  border: none;
  font-size: 15px;
  padding: 10px 12px;
}

::v-deep .blog-filter-nav .el-button:hover {
  color: #000c17;
  background: #ffffff;
}

::v-deep .blog-filter-nav .el-button:focus {
  color: #606266;
  background: #FFF;
}

::v-deep .is_active.el-button {
  color: #000c17;
}

::v-deep .blog-filter-nav .el-button.is-disabled {
  padding: 10px;
}

::v-deep .blog-filter-nav .el-button.is-disabled:hover {
  border: none;
  font-size: 15px;
  color: #C0C4CC;
  cursor: auto;
}

.blog-mamage-item {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-bottom: 1px dashed #D3D4D6;
}

.blog-mamage-item-title,
.blog-manage-item-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.blog-mamage-item-title .item-title {
  font-size: 17px;
  color: #434348;
  text-decoration: none;
}

.blog-mamage-item-title .item-title:hover {
  color: #409eff;
}

.blog-mamage-item-title .blog-mamage-item-time {
  font-size: 14px;
  color: #8c939d;
}

.blog-mamage-item-type {
  margin: 12px 0;
}

.el-tag:first-child {
  margin-left: 0;
}

.item-info-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blog-manage-item-info .item-info-left p {
  margin-right: 15px;
  font-size: 14px;
  color: #8c939d;
}

.blog-manage-item-info .item-info-left p span {
  margin-left: 5px;
}

.item-info-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item-info-right p {
  margin-left: 15px;
  font-size: 14px;
}

.item-info-right p a {
  color: #434348;
  text-decoration: none;
}

.item-info-right p a:hover {
  color: #8c939d;
}

.el-dropdown {
  cursor: pointer;
}

::v-deep .el-dropdown-menu__item,
::v-deep .el-dropdown-menu__item:hover {
  padding: 1px 10px !important;
  background: #ffffff !important;
  text-align: center;
}

::v-deep .el-dropdown-menu__item .el-button {
  padding: 5px 15px;
  font-size: 14px !important;
}

::v-deep .el-dropdown-menu__item .el-button:hover {
  background: #ecf5ff;
}
</style>