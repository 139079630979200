<template>
  <div>

    <el-dialog title="专栏信息" :visible.sync="dialogVisible" @closed="handleClose(false)">
      <el-form :model="form" label-width="80px" size="small" ref="form" :rules="rules">
        <el-form-item prop="tagName" label="专栏名称">
          <el-input type="textarea" v-model="form.tagName" resize="none" :autosize="{ minRows: 2, maxRows: 4}"
                    autocomplete="off" onkeydown="if (event.keyCode===13){return false;}"></el-input>
        </el-form-item>
        <el-form-item prop="tagDesc" label="专栏简介">
          <el-input type="textarea" v-model="form.tagDesc" resize="none" :autosize="{ minRows: 3, maxRows: 5}"
                    maxlength="200" :show-word-limit="true" autocomplete="off"
                    onkeydown="if (event.keyCode===13){return false;}"></el-input>
        </el-form-item>
        <el-form-item label="专栏图标">
          <div style="display: flex;align-items: center">
            <el-upload
                class="avatar-uploader"
                :action="this.$baseUrl+'/file/upload'"
                :headers="{token : user.token}"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="form.thumbnail" :src="form.thumbnail" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose(false)">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "columnDialog",
  props: {
    user: {
      type: Object,
      required: true
    },
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        tagName: '',
        tagDesc: '',
        thumbnail: ''
      },
      rules: {
        tagName: [
          {required: true, message: '请输入专栏名称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 10 个字符', trigger: 'blur'}
        ],
        tagDesc: [
          {required: true, message: '请输入专栏简介', trigger: 'blur'},
        ],
      },
    }
  },
  watch: {
    dialogFormVisible: function (newVal) {
      this.dialogVisible = newVal
    }
  },
  methods: {
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$request.post("customtag/add", this.form).then(res => {
            if (res.code == '200') {
              this.$message.success("操作成功");
              this.dialogVisible = false;
              this.$emit('get-column')
            } else {
              this.$message.error(res.msg);
            }
          })
        }
      })
    },
    handleClose(flag) {
      this.$emit('handle-close-dialog', flag)
    },
    handleAvatarSuccess(response, file, fileList) {
      // 把user的头像属性换成上传的图片的链接
      this.form.thumbnail = response.data
      this.$forceUpdate()
      //console.log(response.data)
    },
    beforeAvatarUpload(file) {
      const isJPG =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、jpeg格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
  }
}
</script>

<style scoped>
::v-deep .el-dialog {
  width: 100% !important;
  max-width: 576px !important;
  margin: 20px auto;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>