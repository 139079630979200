<template>
  <div class="page-403">
    <div class="page-403-container">
      <img src="@/assets/img/403.svg">
      <div class="page-403-desc">
        <div class="page-403-desc-title">403</div>
        <div class="page-403-desc-text">抱歉，您没有访问权限,请联系管理员</div>
        <div class="page-403-desc-text">
          <router-link to="/">返回首页</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page-403"
}
</script>

<style scoped>
.page-403 {
  background-color: #f7f7f7;
}

.page-403-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.page-403-img {
  width: 100px;
  height: 100px;
}

.page-403-desc {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-403-desc-title {
  font-size: 18px;
  color: #323233;
  margin-bottom: 10px;
}

.page-403-desc-text {
  font-size: 14px;
  color: #969799;
}
/*给router-link-active设置样式*/
.page-403-desc-text a.router-link-active {
  color: #409eff;
}

/* 适配 */
@media screen and (max-width: 768px) {
  .page-403-img {
    width: 80px;
    height: 80px;
  }

  .page-403-desc-title {
    font-size: 14px;
  }

  .page-403-desc-text {
    font-size: 12px;
  }
}
</style>