<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',

  data() {
    return {
      user: {}
    }
  },
  created() {
    this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : {}
  },
  mounted() {
    this.getUser()
  },
  methods: {
    getUser(){
      if (this.user.id ==null) return
      this.$request.get('/user/selectById/' + this.user.id).then(res => {
        if (res.code == '200'){
          return
        } else {
          localStorage.removeItem('xh')
          this.$router.push('/')
          return;
        }
      })
    }
  }
}
</script>