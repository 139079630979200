<template>
  <div>
    <el-card :body-style="{ padding: '10px' }">
      <el-menu
          router
          mode="horizontal"
          text-color="rgba(0, 0, 0)"
          active-text-color="#1687ff"
          :default-active="$route.path"
          style="border: none">
        <el-menu-item index="/creator/comment">
          <span slot="title">评论</span>
        </el-menu-item>
      </el-menu>
      <el-main class="comment">

        <ul class="comment-nav">
          <li v-for="(item,index) in nav" :key="index" @click="active=index">
            <el-button size="medium" :class="index==active?'is_active':''">
              {{ item.name }}
            </el-button>
          </li>
        </ul>

        <myblogComment v-if="active==0"></myblogComment>

        <myComment v-if="active==1"></myComment>

      </el-main>
    </el-card>
  </div>
</template>

<script>
import myblogComment from "@/views/Creator/manage/comment/myblogComment.vue";
import myComment from "@/views/Creator/manage/comment/myComment.vue";

export default {
  name: "comment",
  components: {
    myblogComment, myComment
  },
  data() {
    return {
      nav: [
        {name: "我的文章评论"},
        {name: "我发布的评论"}
      ],
      active: 0,
    }
  },
}
</script>

<style scoped>
::v-deep .el-card__body {
  min-height: calc(100vh - 80px);
}

.comment {
  padding: 10px;
}

.comment-nav {
  width: 100%;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

::v-deep .comment-nav .el-button {
  border: none;
  font-size: 15px;
  padding: 10px 12px;
}

::v-deep .comment-nav .el-button:hover {
  color: #000c17;
  background: #ffffff;
}

::v-deep .comment-nav .el-button:focus {
  color: #606266;
  background: #FFF;
}

::v-deep .is_active.el-button {
  color: #000c17 !important;
}
</style>