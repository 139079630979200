<template>
  <div>
    <article class="excerpt" v-for="(item,index) in list" :key="index" :class="'excerpt'+index">
      <div class="left">
        <a class="focus" :href="'/p/'+item.id" :title=item.title target="_blank">
          <img :src=item.thumbnail :alt=item.title>
        </a>
      </div>
      <div class="right">
        <header>
          <a class="cat pcShow" v-if="item.columnName != null" :href="'/p/'+item.id"
             :title="item.columnName">{{ item.columnName }}<i></i></a>
          <div class="title">
            <a :href="'/p/'+item.id" :title=item.title target="_blank">{{ item.title }}</a>
            <el-tag v-if="item.isOriginal==1" size="mini" class="pcShow" style="font-weight: normal;">原创</el-tag>
          </div>
        </header>
        <a class="summary" :href="'/p/'+item.id" target="_blank">
          <p class="note">{{ item.summary }}</p>
        </a>

        <p class="meta">
          <a class="author" :href="'/people/'+item.authorUsername" title="作者" target="_blank">
            <i class="el-icon-user"></i>{{ item.author }}
          </a>
          <time class="time"><i class="el-icon-time"></i>
            {{ formatDate(item.createTime) }}
          </time>
          <span class="views"><i class="el-icon-view"></i>{{ item.viewCount }}</span>
          <a class="comment" :href="'/p/'+item.id" title="评论" target="_blank">
            <i class="el-icon-chat-line-square"></i>
            {{ item.commentCount==null ? 0 : item.commentCount }}
          </a>
        </p>
      </div>
    </article>
  </div>
</template>

<script>

export default {
  name: "homeIndex",
  props: ['list'],
  data() {
    return {
      //视窗宽度
      windowWidth: 0,
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    formatDate(time) {
      return this.$moment(time == null ? '' : time).format('YYYY-MM-DD');
    }
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
</script>

<style>
.excerpt {
  border-top: 1px solid #EAEAEA;
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #FFFFFF;
}

.excerpt:hover {
  background-color: #FBFDFF;
  position: relative;
}

.excerpt0 {
  border-top: none;
}

.excerpt .left a {
  width: var(--w);
  height: var(--h);
  overflow: hidden;
  display: block;
}

.excerpt .left a:hover img {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.excerpt .left img {
  width: var(--w);
  height: var(--h);
  aspect-ratio: 3/2;
  object-fit: scale-down;
  transition: all .5s;
  display: inline;
}

.excerpt .right {
  margin-left: 20px;
  flex: 1;
  position: relative;
}

.excerpt .right header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.excerpt .right .title {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
  font-weight: bold;

}

.excerpt .right .title a {
  color: #555;
  width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.excerpt .right .title a:hover {
  color: #3399CC;
}

.excerpt .right header a:hover {
  text-decoration: none;
}

.excerpt .right .cat {
  color: #FFF;
  background-color: #3399CC;
  padding: 3px 6px;
  font-size: 12px;
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 6px;
}

.excerpt .right .cat:hover {
  opacity: .8;
}

.excerpt .right .cat i {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: -4px;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-left: 4px solid #3399CC;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}

.excerpt .right .meta {
  width: 100%;
  color: #999;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
}

.excerpt .right .author,
.excerpt .right .time,
.excerpt .right .comment,
.excerpt .right .views {
  cursor: pointer;
  margin-right: 20px;
}

.excerpt .right .author:hover,
.excerpt .right .time:hover,
.excerpt .right .comment:hover,
.excerpt .right .views:hover {
  color: #3399CC;
  text-decoration: none;
}

@media (max-width: 680px) {
  .excerpt .right .comment {
    display: none;
  }
}

.excerpt .right .meta a {
  color: #999;
}

.excerpt .right .mate i {
  font-size: 16px;
}

.excerpt .right .summary:hover {
  cursor: pointer;
  color: #8c939d;
  text-decoration: none;
}

.excerpt .right .note {
  font-size: 14px;
  color: #999;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/**媒体查询：平板端：576-768px，手机端：小于576px*/
@media (min-width: 768px) and (max-width: 992px) {

}

@media (max-width: 768px) {
  .excerpt .right .title a {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .excerpt .right .pcShow {
    display: none;
  }
}

@media (max-width: 576px) {
  .excerpt {
    padding: 10px 5px;
  }

  .excerpt .left a {
    width: var(--sw);
    height: var(--sh);
  }

  .excerpt .left img {
    width: var(--sw);
    height: var(--sh);
    aspect-ratio: 3/2;
    object-fit: scale-down;
    transition: all .5s;
    display: inline;
  }

  .excerpt .right {
    margin-left: 10px;
  }

  .excerpt .right .title {
    font-size: 14px;
  }

  .excerpt .right .meta {
    font-size: 12px;
  }

  .excerpt .right .author,
  .excerpt .right .time,
  .excerpt .right .comment,
  .excerpt .right .views {
    cursor: pointer;
    margin-right: 5px;
  }

  .excerpt .right .summary{
    display: none;
  }
}
</style>