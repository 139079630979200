<template>
  <div>
    <div class="myblog-comment">
      <div class="myblog-comment-item" v-for="item in myblogCommentList" :key="item.id">
        <div class="myblog-comment-item-title">
          <p class="comment-avatar">
            <el-avatar :size="32" :src="item.userAvatar"></el-avatar>
          </p>
          <p class="comment-user">{{ item.createByName }}</p>
          <p class="comment-date">{{ formatDate(item.createTime) }}</p>
          <p>评论了你的文章</p>
          <a :href="'/p/'+item.blogId" class="comment-article">{{ item.blogTitle }}</a>
          <p class="comment-operation">
            <el-link @click="deleteComment(item.id)" :underline="false">删除</el-link>
          </p>
        </div>
        <div class="myblog-comment-item-content">
          <i class="el-icon-chat-dot-square"></i>
          <p class="comment-content">{{ item.content }}</p>
        </div>
      </div>

    </div>

    <div style="padding:10px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :hide-on-single-page="true"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total,sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "myblogComment",
  data() {
    return {
      myblogCommentList: [],
      active: 0,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizeList: [10, 20, 50, 100],
    }
  },
  mounted() {
    this.myblogComment();
  },
  methods: {
    myblogComment() {
      this.$request.get("/comment/myblogComment", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        }
      }).then(res => {
        if (res.code === '200') {
          this.myblogCommentList = res.data.records;
          this.total = res.data.total;
        }
      })
    },
    deleteComment(id) {
      this.$confirm('删除评论会将回复您的评论一并删除，确定删除该评论吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$request.delete('/comment/delete', {
          params: {
            cid: id
          }
        }).then(res => {
          if (res.code == '200') {
            this.$message.success('删除成功')
            this.myblogComment();
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    handleSizeChange(val) {/*传递过来当前是第几页*/
      this.pageSize = val;  //获取当前每页显示条数
      this.myblogComment();
    },
    handleCurrentChange(val) {/*传递过来当前是第几页*/
      this.pageNum = val;   //获取当前第几页
      this.myblogComment();
    },
    formatDate(time) {
      return this.$moment(time == null ? '' : time).format('YYYY-MM-DD');
    }
  }
}
</script>

<style scoped>
.myblog-comment-item {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
}

.myblog-comment-item-title,
.myblog-comment-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2.5px 0;
}

.comment-user,
.comment-date,
.comment-article {
  margin: 0 5px;
}

.comment-date {
  color: #8c939d;
  font-size: 15px;
}

.comment-operation {
  margin-left: auto;
  display: none;
}

.myblog-comment-item:hover .comment-operation {
  display: block;
}
.myblog-comment-item-content{
  padding-left: 32px;
}

.myblog-comment-item-content .comment-content {
  margin-left: 10px;
}
</style>