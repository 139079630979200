<template>
  <div>
    <el-row :gutter="10">
      <el-col :md="6" :sm="7" :xs="24" class="aside" v-if="windowWidth>768">
        <Creator/>
        <Calendar/>
      </el-col>
      <el-col :md="18" :sm="17" :xs="24" class="main">
        <el-card :body-style="{ padding: '15px' }">
          <div class="pub-dynamic">
            <div class="pub-input">
              <el-input
                  type="textarea"
                  :autosize="{minRows: 4, maxRows: 6}"
                  placeholder="快来发布动态吧!"
                  minlength="1"
                  maxlength="1000"
                  :show-word-limit="true"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  v-model="content">
              </el-input>
            </div>
            <div class="pub-action">
              <el-button type="primary" :size="windowWidth<576 ? 'mini': 'medium'">发布</el-button>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Creator from "@/components/home/creator.vue";
import Calendar from "@/components/home/calendar.vue";
import reply from "@/components/comment/reply.vue";

export default {
  name: "dynamic",
  components: {
    Creator, Calendar, reply,
  },
  data() {
    return {
      user: null,
      content: '',
      //视窗宽度
      windowWidth: 0,
    }
  },
  created() {
    this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : {};
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
    document.title = '动态 - XiaohuaBlog';
  },
  methods: {
    handleClose() {
      this.drawer = false
    },
    handleFocus() {
      this.isFocused = true
    },
    handleBlur() {
      this.isFocused = false
    },
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
</script>

<style scoped>
.aside {
  display: grid;
  grid-gap: 10px;
}

.pub-dynamic {
  display: flex;
  flex-direction: column;
}


.pub-input {
  flex: 1;
  border-radius: 5px;
  border: 1px solid #f1f2f3;
  background: #f1f2f3;
}

::v-deep .pub-input .el-textarea__inner {
  min-height: auto;
  padding: 5px 10px;
  font-size: 16px;
  color: #000c17;
  background: transparent;
  resize: none;
  border: none;
  transition: min-height 0.3s ease;
}

::v-deep .pub-input .focused .el-textarea__inner {
  /* 当聚焦时应用的样式 */
  min-height: 88px !important; /* 聚焦时的高度 */
}

::v-deep .pub-input .el-textarea__inner::placeholder {
  color: #5d5b5b;
}

::v-deep .pub-input:has(.el-textarea__inner:focus) {
  background: #ffffff;
  border: 1px solid #409eff;
}

::v-deep .pub-input .el-textarea .el-input__count{
  background: #f1f2f3;
}

::v-deep .pub-input:has(.el-textarea__inner:focus) .el-input__count{
  background: #ffffff;
}

.pub-action {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-top: 10px;
}

.pub-action p {
  font-size: 13px;
  color: #8c939d;
}

@media screen and (max-width: 576px) {
  ::v-deep .el-card__body {
    padding: 10px !important;
  }
}
</style>