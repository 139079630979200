<template>
  <div>
    <el-card :body-style="{ padding: '10px' }">
      <el-menu
          router
          mode="horizontal"
          text-color="rgba(0, 0, 0)"
          active-text-color="#1687ff"
          :default-active="$route.path"
          style="border: none">
        <el-menu-item index="/creator/column">
          <span slot="title">专栏</span>
        </el-menu-item>
      </el-menu>
      <el-main class="column">

        <ul class="column-nav">
          <li v-for="(item,index) in nav" :key="index" @click="active=index">
            <el-button size="medium" :class="index==active?'is_active':''" @click="item.func" :icon="item.icon">
              {{ item.name }}
            </el-button>
          </li>
        </ul>
        <div style="display: flex;justify-content: end;align-items: center;margin-bottom: 10px;">
          <el-button type="primary" size="medium" @click="addColumn" icon="el-icon-plus">新增专栏</el-button>
        </div>

        <el-table :data="tableData" style="min-height: 50vh" lazy v-loading="loading"
                  element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                  :header-cell-style="{background:'transparent',color:'#3d3c3c'}"
                  :stripe="isStripe">
          <template slot="empty">
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column label="专栏名称">
            <template v-slot="scope">
              <a href="" style="display: flex;align-items: center;cursor: pointer; color: #3e4450;">
                <img :src="scope.row.thumbnail" :alt="scope.row.tagName" v-if="windowWidth>576"
                     style="width: 50px;height: 50px;border-radius: 5px;box-shadow: 0 0 1px #ccc;">
                <p style="font-size: 16px;font-weight: bolder; margin-left: 5px;">{{ scope.row.tagName }}</p>
              </a>
            </template>
          </el-table-column>
          <el-table-column prop="tagDesc" label="描述">
          </el-table-column>
          <el-table-column prop="audit" label="审核" v-if="active==1" width="80px">
            <template v-slot="scope">
              <el-tag type="danger" v-if="scope.row.audit==0">未通过</el-tag>
              <el-tag type="info" v-if="scope.row.audit==2">审核中</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="文章数" width="80px">
            <template slot-scope="scope">
              {{ scope.row.count ? scope.row.count : 0 }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" :formatter="formatDate" label="创建时间">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div v-if="active!=2">
<!--                <el-link type="primary" :underline="false" @click="handleManage(scope.row)" v-if="scope.row.audit==1">-->
<!--                  添加文章-->
<!--                </el-link>-->
<!--                <el-divider direction="vertical"></el-divider>-->
                <el-link type="primary" :underline="false" @click="handleEdit(scope.row)">编辑</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link type="danger" :underline="false" @click="handleDel(scope.row)">删除</el-link>
              </div>
              <div v-else>
                <el-link type="primary" :underline="false" @click="handleRecover(scope.row)">恢复</el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link type="danger" :underline="false" @click="handleDelete(scope.row.id)">彻底删除</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding:10px">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </el-main>
    </el-card>
    <el-dialog title="专栏信息" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form" label-width="80px" size="small" ref="form" :rules="rules">
        <el-form-item prop="tagName" label="专栏名称">
          <el-input type="textarea" v-model="form.tagName" resize="none" :autosize="{ minRows: 2, maxRows: 4}"
                    autocomplete="off" onkeydown="if (event.keyCode===13){return false;}"></el-input>
        </el-form-item>
        <el-form-item prop="tagDesc" label="专栏简介">
          <el-input type="textarea" v-model="form.tagDesc" resize="none" :autosize="{ minRows: 3, maxRows: 5}"
                    maxlength="200" :show-word-limit="true" autocomplete="off"
                    onkeydown="if (event.keyCode===13){return false;}"></el-input>
        </el-form-item>
        <el-form-item label="专栏图标">
          <div style="display: flex;align-items: center">
            <el-upload
                class="avatar-uploader"
                :action="this.$baseUrl+'/file/upload'"
                :headers="{token : user.token}"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
              <img v-if="form.thumbnail" :src="form.thumbnail" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "column",
  data() {
    return {
      user: {},
      nav: [
        {func: this.all, name: "全部", icon: ""},
        {func: this.audits, name: "审核", icon: ""},
        {func: this.recycle, name: "回收站", icon: "el-icon-delete"},
      ],
      active: 0,
      audit: null,
      unaudit: null,
      delFlag: null,
      windowWidth: 0,
      tableData: [],
      isStripe: true,//是否显示斑马纹
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizeList: [10, 20, 50, 100],
      loading: true,
      dialogFormVisible: false,
      form: {},
      rules: {
        tagName: [
          {required: true, message: '请输入专栏名称', trigger: 'blur'},
          {min: 2, max: 20, message: '长度在 2 到 10 个字符', trigger: 'blur'}
        ],
        tagDesc: [
          {required: true, message: '请输入专栏简介', trigger: 'blur'},
        ],
      },
    }
  },
  created() {
    this.user = localStorage.getItem("xh") ? JSON.parse(localStorage.getItem("xh")) : {}
  },
  mounted() {
    this.all();
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    all() {
      this.audit = "1";
      this.unaudit = null;
      this.delFlag = "1";
      this.load();
    },
    audits() {
      this.audit = null;
      this.unaudit = "1";
      this.delFlag = "1";
      this.load();
    },
    recycle() {
      this.audit = null;
      this.unaudit = null;
      this.delFlag = "0";
      this.load();
    },
    addColumn() {
      this.form = {};
      this.dialogFormVisible = true;
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true;
    },
    handleManage(row){

    },
    load() {
      this.$request.get("customtag/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          id: this.user.id,
          audit: this.audit,
          unaudit: this.unaudit,
          delFlag: this.delFlag
        }
      }).then(res => {
        if (res.code == '200') {
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$request({
            url: "customtag/" + (this.form.id ? "update" : "add"),
            method: this.form.id ? "put" : "post",
            data: this.form
          }).then(res => {
            if (res.code == '200') {
              this.$message.success("操作成功");
              this.dialogFormVisible = false;
              this.load();
            } else {
              this.$message.error(res.msg);
            }
          })
        }
      })
    },
    handleDel(row) {
      this.$confirm('是否确定要删除当前专栏？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        row.delFlag = "0";
        this.$request.put("customtag/update", row).then(res => {
          if (res.code == '200') {
            this.$message.success("删除成功！");
            this.load();
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
        this.$message.info('已取消操作')
      })
    },
    handleRecover(row) {
      this.$confirm('此操作将恢复该专栏, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        row.audit = "2";
        row.delFlag = "1";
        this.$request.put("customtag/update/", row).then(res => {
          if (res.code == '200') {
            this.$message.success("恢复成功！");
            this.load();
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
        this.$message.info('已取消操作')
      })
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该专栏, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$request.delete("customtag/delete/" + id).then(res => {
          if (res.code == '200') {
            this.$message.success("删除成功！");
            this.load();
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {
        this.$message.info('已取消操作')
      })
    },
    handleSizeChange(val) {/*传递过来当前是第几页*/
      this.pageSize = val;  //获取当前每页显示条数
      this.load();
    },
    handleCurrentChange(val) {/*传递过来当前是第几页*/
      this.pageNum = val;   //获取当前第几页
      this.load();
    },
    handleAvatarSuccess(response, file, fileList) {
      // 把user的头像属性换成上传的图片的链接
      this.form.thumbnail = response.data
      this.$forceUpdate()
      //console.log(response.data)
    },
    beforeAvatarUpload(file) {
      const isJPG =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、jpeg格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    formatDate(row) {
      return this.$moment(row.updateTime == null ? '' : row.updateTime).format('YYYY-MM-DD HH:mm:ss');
    }

  }
}
</script>

<style scoped>
::v-deep .el-card__body {
  min-height: calc(100vh - 80px);
  overflow-x: scroll !important;
}

.column {
  padding: 10px;
}

.column-nav {
  width: 100%;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

::v-deep .column-nav .el-button {
  border: none;
  font-size: 15px;
  padding: 10px 12px;
}

::v-deep .column-nav .el-button:hover {
  color: #000c17;
  background: #ffffff;
}

::v-deep .column-nav .el-button:focus {
  color: #606266;
  background: #FFF;
}

::v-deep .is_active.el-button {
  color: #000c17 !important;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .el-dialog {
  width: 100% !important;
  max-width: 576px !important;
  margin: 20px auto;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>