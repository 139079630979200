<template>
  <div>
    <div v-if="!isnotfound">
      <div class="article">
        <div class="editor-content-view" id="editor-content-view">
          <div class="article-title"><h1>{{ article.title }}</h1></div>
          <div class="article-info">
            <div style="margin-bottom: 5px;">
              <p>
                <el-tag v-if="article.isOriginal==1" type="warning">原创</el-tag>
                <el-tag v-else type="info" size="mini">转载</el-tag>
              </p>
              <p>
                <a class="author" :href="'/people/'+article.authorUsername" target="_blank">
                  <i class="el-icon-user"></i>{{ article.author }}
                </a>
              </p>
              <p>
                <span class="location"><i class="el-icon-location-outline"></i>发布于 {{ article.ipAttr }}</span>
              </p>
              <p>
                <el-popover
                    placement="bottom-start"
                    width="220"
                    trigger="hover"
                    :disabled="article.updateTime!=null ? false : true"
                    :content="'更新于'+formatDate(article.updateTime)">
                  <span class="time" slot="reference">
                    <i class="el-icon-time"></i>
                    {{ formatDate(article.createTime) }}
                  </span>
                </el-popover>
              </p>
              <p>
                <el-popover
                    placement="bottom-start"
                    width="80"
                    trigger="hover"
                    :content="'浏览量：'+ article.viewCount">
                  <span class="views" slot="reference">
                    <i class="el-icon-view"></i>
                    {{ article.viewCount }}
                  </span>
                </el-popover>
              </p>

              <p v-if="article.status==0" style="margin-left: auto;">
                <el-tag type="info" size="mini">草稿</el-tag>
              </p>

            </div>
            <div>
              <p class="tags" v-if="columnName!=null">
                <i class="el-icon-zhuanlan"></i>
                <el-tag size="mini" @click="$router.push({name:'search',query:{keywords: columnName}})">
                  {{ columnName }}
                </el-tag>
              </p>
              <p class="tags">
                <i class="el-icon-tag"></i>
                <el-tag v-for="tag in tags" :key="tag.id" size="mini"
                        @click="$router.push({name:'search',query: {keywords: tag.name}})">{{ tag.name }}
                </el-tag>
              </p>
            </div>
          </div>
          <el-skeleton :rows="5" animated :loading="loading"/>

          <div v-html="article.content" class="line-numbers article-content" id="article"></div>

        </div>
        <div class="author-info-fixed"></div>
        <div class="author-info" :class="{ authorIs_fixed: isFixed }" :style="{width:artWidth}">
          <div>
            <a class="author-box" :href="'/people/'+article.authorUsername" :title="article.author" target="_blank">
              <el-avatar :size="36" :src="article.avatar" class="author-avatar"
                         :icon="article.avatar==null?'el-icon-user-solid': ''"></el-avatar>
              <span class="author-name">{{ article.author }}</span>
            </a>
          </div>
          <div>
            <p class="commentIcon" @click="handleComment">
              <i class="el-icon-chat-line-square"></i>{{ commentNum }}
            </p>
            <p>
              <i class="el-icon-share"></i>
            </p>
          </div>
        </div>
        <!-- 相关推荐-->
        <!--        <div class="related-article">-->
        <!--          <h2>相关推荐</h2>-->

        <!--        </div>-->
      </div>
      <!-- 版权 -->
      <copyright style="margin: 5px auto 0 auto;text-align: center;max-width: 840px;"/>

      <el-drawer
          :title="'评论('+commentNum+')'"
          :visible.sync="drawer"
          :before-close="handleClose"
          :append-to-body="true"
          :modal-append-to-body="false"
          custom-class="comment-drawer"
          :direction="drawerDirection">

        <div class="reply">
          <!-- 发布评论 -->
          <div class="reply-avatar">
            <el-avatar :size="36" :src="user.avatar" :icon="user.avatar==null?'el-icon-user-solid': ''"></el-avatar>
          </div>
          <div class="reply-input">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 5}"
                placeholder="善语结善缘，恶语伤人心"
                minlength="1"
                maxlength="1000"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleInput"
                ref="inputRef"
                :class="{ focused: isFocused }"
                v-model="commentForm.content">
            </el-input>
            <div class="action">
              <p class="count">{{ contentLength }}/1000</p>
              <el-button type="primary" size="mini" :disabled="contentLength > 0 ? false : true " @click="addComment">
                评论
              </el-button>
            </div>
          </div>
        </div>
        <!-- 显示评论 -->
        <div class="comment-list-container">
          <div class="comment-list-box comment-operate-item">
            <ul class="comment-list" v-for="comment in comments">
              <!-- 评论根目录 -->
              <root :comment="comment" :article="article" :getComments="getComments" :show-reply-id="showReplyId"
                    @toggle-reply="toggleReply"></root>
              <!-- 评论子目录 -->
              <li class="replay-box" style="display: block;">
                <ul class="comment-list">
                  <!-- 子组件递归实现 -->
                  <child :childComments="comment.children" :parentComment="comment" :article="article"
                         :rootPid="comment.id" :getComments="getComments" :show-reply-id="showReplyId"
                         @toggle-reply="toggleReply"
                         v-if="comment.children != null"></child>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <!-- 暂无评论的空状态 -->
        <el-empty description="暂无评论" v-show="commentNum === 0"></el-empty>
      </el-drawer>
    </div>
    <Page404 v-else></Page404>
  </div>
</template>

<script>
import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers.min';//行号插件
import 'prismjs/plugins/line-numbers/prism-line-numbers.min.css';//行号插件的样式
import 'vue-photo-preview/dist/vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import Page404 from "@/views/codeView/Page-404.vue";
import root from "@/components/comment/rootComment.vue";
import child from "@/components/comment/childComment.vue";
import copyright from "@/components/copyright.vue";

export default {
  name: "detail",
  components: {
    copyright,
    Page404, root, child
  },
  data() {
    return {
      user: {},
      loading: true,
      article: {
        tag: null,
      },
      artWidth: 0,
      isFixed: true,
      tags: null,
      columnName: null,
      drawer: false,
      drawerDirection: 'rtl',
      isFocused: false,
      commentForm: {
        content: '',
      },
      contentLength: 0,
      comments: [],
      commentNum: 0,
      showReplyId: null, // 初始时没有回复显示
      isnotfound: false,
      observer: null,
    }
  },
  created() {
    this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : {}
    // 加载文章
    this.getArticle();
  },
  mounted() {
    //监听窗口大小变化
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.initObserver();
  },
  methods: {
    //窗口大小变化
    handleResize() {
      if (this.isnotfound == true) {
        return;
      }
      //获取.aricle的宽度
      let articleWidth = document.querySelector('.article').offsetWidth;
      this.artWidth = articleWidth + 'px';
      this.drawerDirection = 'rtl'
      if (window.innerWidth < 576) {
        this.drawerDirection = 'btt'
      }
    },
    getArticle() {
      this.$request.get('/article/p/' + this.$route.params.id).then(res => {
        if (res.code == '200') {
          this.loading = false
          this.article = res.data
          if (this.article.categoryId) this.getColumnName(this.article.categoryId)
          this.getTags(this.article.tag)
          this.getComments(this.article.id)
          document.title = res.data.title + " -- XiaohuaBlog"
          setTimeout(() => {
            let preBlocks = document.querySelectorAll('pre')
            var code = document.getElementsByTagName('code')
            for (var i = 0; i < code.length; i++) {

              //获取code的class的长度为零，添加一个类名
              if (code[i].className.length == 0) {
                code[i].className = "language-none"
                preBlocks.forEach(preBlock => {
                  preBlock.setAttribute("data-prismjs-copy", "复制");
                  preBlock.setAttribute("data-prismjs-copy-error", "按Ctrl+C复制");
                  preBlock.setAttribute("data-prismjs-copy-success", "复制成功");
                });
              }
            }
            var article = document.getElementById('article')
            var img = article.getElementsByTagName('img')
            for (var i = 0; i < img.length; i++) {
              //添加preview属性
              img[i].setAttribute("preview", this.article.id)
            }
            Prism.highlightAll();
          }, 200);
        } else if (res.code == '404') {
          this.isnotfound = true
        }
      })
    },
    //查询专栏名称
    getColumnName(id) {
      this.$request.get('/customtag/getTag/' + id).then(res => {
        if (res.code == '200') {
          this.columnName = res.data
        } else {
          this.columnName = null
        }
      })
    },
    //查询标签名称 params:ids:1,2,3,4
    getTags(ids) {
      this.$request.get('/category/ids/' + ids).then(res => {
        if (res.code == '200') {
          this.tags = res.data
        }
      })
    },
    getComments(id) {
      this.$request.get('/comment/list/' + id).then(res => {
        if (res.code == "200") {
          this.comments = res.data.records
          this.commentNum = res.data.total
        }
      })
    },
    addComment() {
      this.commentForm.blogId = this.article.id
      if (this.commentForm.content.replace(/\n/g, '').length == 0) {
        this.$message.info("评论内容不能为空")
        return
      }
      //将回车替换为<br>
      this.commentForm.content = this.commentForm.content.replace(/^\n+|\n+$/g, '').replace(/\n+/g, '<br>');
      this.$request.post("/comment/add", this.commentForm).then(res => {
        if (res.code == "200") {
          this.$message.success("评论成功")
          this.commentForm.content = ''
          this.contentLength = 0
          this.getComments(this.article.id)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleComment() {
      if (this.user.id == null) {
        this.$message.info("请先登录")
        return;
      }
      if (this.article.isComment == '1' && this.article.status == '1') {
        this.drawer = true
      } else {
        this.$message.info("作者已关闭该文章的评论")
      }
    },
    handleClose() {
      this.drawer = false
    },
    handleFocus() {
      this.isFocused = true
      this.toggleReply(null)
    },
    handleBlur() {
      this.isFocused = false
    },
    handleInput() {
      this.commentForm.content = this.commentForm.content.replace(/\n/g, '')
      this.contentLength = this.commentForm.content.replace(/\n/g, '').length
    },
    toggleReply(commentId) {
      this.showReplyId = this.showReplyId === commentId ? null : commentId;
    },
    initObserver() {
      //监听滚动事件
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) {
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      }, {
        threshold: [0, 1]
      });
      this.observer.observe(document.querySelector('.author-info-fixed'));
    },
    formatDate(time) {
      return this.$moment(time == null ? new Date() : time).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect(); // 停止观察
      this.observer = null; // 清除引用
    }
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style src="prismjs/themes/prism-one-dark.css"></style>
<style>
.article {
  overflow: hidden;
  max-width: 840px;
  margin: 0 auto;
}

.article-title h1 {
  font-size: 28px !important;
}

.article-info {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
}

.article-content {
  padding-top: 30px;
}

.el-popover {
  padding: 3px 10px !important;
}

.el-popper[x-placement^="bottom"] {
  margin-top: 6px !important
}

.article-info div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.article-info div .author,
.article-info div .location,
.article-info div .time,
.article-info div .views,
.article-info div .tags {
  color: #999;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.article-info div .author i,
.article-info div .location i,
.article-info div .time i,
.article-info div .views i,
.article-info div .tags i {
  font-size: 15px;
  margin-left: 5px;
}

.article-info div p {
  margin: 5px 0;
}

.article-info div p ~ p {
  margin-left: 15px;
}

.author-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.06);
  z-index: 999;
}

.author-info div,
.author-info div p,
.author-info .author-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #222226;
  text-decoration: none;
  user-select: none;
}

.author-info .author-avatar {
  margin-right: 8px;
  cursor: pointer;
}

.author-info .author-name {
  display: block;
  font-weight: bold;
  cursor: pointer;
}

.author-info div p {
  padding: 0 3px;
  margin: 0 3px;
  cursor: pointer;
}

.author-info div p i {
  font-size: 20px;
  margin-right: 4px;
}

.authorIs_fixed {
  position: fixed;
  bottom: 0;
}

.comment-drawer {
  width: 480px !important;
}

.el-drawer__body {
  padding: 10px 20px;
}

.pre-mac {
  position: relative;
  margin-top: -7px;
  top: 21px;
  left: 10px;
  width: 100px;
  z-index: 99;
}

.code-toolbar:before {
  content: "";
  display: block;
  background: url("@/assets/img/Trafficlight.png");
  height: 28px;
  width: 100%;
  background-size: 40px;
  background-repeat: no-repeat;
  background-color: #272b33;
  margin-top: 0.5em;
  margin-bottom: 0;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  background-position: 15px 15px;
}


.reply {
  display: flex;
  flex-direction: row;
}

.reply-avatar {
  padding: 5px 5px 0 0;
}

.reply-input {
  flex: 1;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #f1f2f3;
  background: #f1f2f3;
}

.reply-input .el-textarea__inner {
  min-height: auto;
  padding: 5px 10px;
  font-size: 16px;
  color: #000c17;
  background: transparent;
  resize: none;
  border: none;
  transition: min-height 0.3s ease;
}

.reply-input .focused .el-textarea__inner {
  /* 当聚焦时应用的样式 */
  min-height: 88px !important; /* 聚焦时的高度 */
}

.reply-input .el-textarea__inner::placeholder {
  color: #5d5b5b;
}

.reply-input:has(.el-textarea__inner:focus) {
  background: #ffffff;
  border: 1px solid #409eff;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 5px 10px 10px;
}

.action p {
  font-size: 13px;
  color: #8c939d;
  margin-right: 20px;
}

.replay-box .comment-list {
  padding-left: 32px;
}

@media (min-width: 768px) {
  .editor-content-view {
    padding: 0px 20px 20px !important;
  }

}

@media (min-width: 576px) and (max-width: 768px) {
  .article {
    width: 100%;
  }

  .editor-content-view {
    padding: 10px 15px !important;
  }

  .comment-drawer {
    width: 400px !important;
  }
}

@media (max-width: 576px) {
  .article {
    width: 100%;
  }

  .editor-content-view {
    padding: 0 10px 10px !important;
  }

  .comment-drawer {
    width: 100% !important;
    height: 90% !important;
  }
}
</style>