import { render, staticRenderFns } from "./columnDialog.vue?vue&type=template&id=4c992310&scoped=true"
import script from "./columnDialog.vue?vue&type=script&lang=js"
export * from "./columnDialog.vue?vue&type=script&lang=js"
import style0 from "./columnDialog.vue?vue&type=style&index=0&id=4c992310&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c992310",
  null
  
)

export default component.exports