<template>
  <div>
    <el-card>
      <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">湘ICP备2024058082号-1</a></p>
      <p>Copyright © 2024 xiaohua.tech 版权所有</p>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "copyright"
}
</script>

<style scoped>
::v-deep .el-card__body {
  padding: 20px 10px;
}

p {
  font-size: 14px;
  color: #8c939d;
}

a:link, a:visited {
  color: #8c939d;
  text-decoration: none;
}
</style>