<template>
  <div>
    <el-container>
      <el-header height="80px">
        <div class="header">
          <el-row class="top">
            <el-col :span="6" :md="6" :sm="7" :xs="10">
              <img src="@/assets/img/logo.png"/>
            </el-col>
            <el-col :span="18" :md="18" :sm="17" style="text-align: right;height: 20px;padding: 0 30px;"
                    class="hidden-xs-only">

            </el-col>
            <!-- 电脑端 导航栏 -->
            <el-col :span="14" :md="14" :sm="12" class="hidden-xs-only">
              <el-menu :default-active="$route.meta.activeMenu ? $route.meta.activeMenu:$route.path"
                       class="el-menu-demo" mode="horizontal" router>
                <el-menu-item index="/">首页</el-menu-item>
                <el-menu-item index="/dynamic">动态</el-menu-item>
                <!--                <el-menu-item index="/resource">资源分享</el-menu-item>-->
<!--                <el-menu-item index="/board">留言板</el-menu-item>-->
                <!-- 搜索框 -->
                <div class="search-bar" v-if="$route.name!='search'">
                  <el-input placeholder="请输入搜索内容..." size="mini" v-model="search">
                    <el-button slot="append" icon="el-icon-search" size="mini" @click="handleSearch"></el-button>
                  </el-input>
                </div>
              </el-menu>
            </el-col>
            <!-- 平板/手机端 导航栏 -->
            <el-col :span="12" :xs="11" class="hidden-sm-and-up">
              <i :class="collapseIcon" style="font-size: 26px;line-height: 60px; float: right;"
                 @click="handleCollapse"></i>
              <el-drawer
                  :visible.sync="drawer"
                  :before-close="handleClose"
                  :append-to-body="true"
                  :modal-append-to-body="false"
                  direction="ltr">
                <div slot="title">
                  <!--                <img src="@/assets/img/logo.png" alt="logo" height="60px">-->
                  <div style="height: 40px;" v-if="user!=null">
                    <img :src="user.avatar" alt=""
                         style="width: 45px; height: 45px; margin-right: 10px;border-radius: 50%; float: left;"/>
                    <span style="font-size: 18px;"> {{ user.nickname }} </span><br>
                    <span style="font-size: 12px; cursor: pointer;vertical-align: bottom;"
                          @click="handleToPeople">个人中心</span>
                  </div>
                  <div class="login" style="justify-content: left" v-else>
                    <router-link to="/login">登录</router-link>
                    /
                    <router-link to="/register">注册</router-link>
                  </div>
                </div>
                <el-menu :default-active="$route.meta.activeMenu ? $route.meta.activeMenu:$route.path"
                         class="el-menu-demo" router mode="vertical" @select="handleClose">
                  <div style="padding: 0 10px;">
                    <el-input placeholder="请输入搜索内容..." size="mini" v-model="search">
                      <el-button slot="append" icon="el-icon-search" size="mini" @click="handleSearch"></el-button>
                    </el-input>
                  </div>
                  <el-menu-item index="/">
                    <i class="el-icon-s-home"></i>
                    <span slot="title">首页</span>
                  </el-menu-item>
                  <el-menu-item index="/dynamic">
                    <i class="el-icon-fadongtai"></i>
                    <span slot="title">动态</span>
                  </el-menu-item>
                  <!--                  <el-menu-item index="/resource">-->
                  <!--                    <i class="el-icon-s-home"></i>-->
                  <!--                    <span slot="title">资源分享</span>-->
                  <!--                  </el-menu-item>-->
<!--                  <el-menu-item index="/board">-->
<!--                    <i class="el-icon-s-home"></i>-->
<!--                    <span slot="title">留言板</span>-->
<!--                  </el-menu-item>-->
                </el-menu>
              </el-drawer>
            </el-col>
            <!-- 个人页 -->
            <el-col :span="4" :md="4" :sm="5" :xs="3" style="text-align: center">
              <el-dropdown placement="bottom" trigger="click" v-if="user!=null">
                <div style="display: flex; align-items: center; cursor: default;margin: 10px;">
                  <img :src="user.avatar" alt="" style="width: 40px; height: 40px; margin: 0 5px;border-radius: 50%"/>
                  <span style="font-size: 17px; height: 40px; line-height: 40px; margin: 0 5px;" class="hidden-xs-only"> {{
                      user.nickname
                    }} </span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleToPeople">个人中心</el-dropdown-item>
                  <el-dropdown-item @click.native="handleToCreator">创作中心</el-dropdown-item>
                  <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div class="login" v-else>
                <router-link to="/login">登录</router-link>
                <span class="hidden-xs-only">/</span>
                <router-link to="/register" class="hidden-xs-only">注册</router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <div class="view">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import bus from '@/utils/eventBus'

export default {
  name: "User",
  data() {
    return {
      isCollapse: false,
      collapseIcon: 'el-icon-s-fold',
      drawer: false,
      windowWidth: 0,
      user: localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : null,
      search: '',
    }
  },
  created() {
    //通过eventBus实现组件之间的通信
    bus.$on('update:user', val => {
      this.user = JSON.parse(JSON.stringify(val))
    })
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    handleCollapse() {
      this.isCollapse = !this.isCollapse
      this.collapseIcon = this.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
      this.drawer = !this.drawer
    },
    handleClose() {
      this.isCollapse = false
      this.collapseIcon = 'el-icon-s-fold'
      this.drawer = false
    },
    handleSearch() {
      if (this.search) {
        this.$router.push({
          path: '/search',
          query: {
            keywords: this.search
          }
        })
      }
    },
    handleToPeople() {
      if (this.user == null) {
        this.$message.error('请先登录')
        return
      }
      this.$router.push('/people/' + this.user.username)
    },
    handleToCreator() {
      if (this.user == null) {
        this.$message.error('请先登录')
        return
      }
      this.$router.push('/creator')
    },
    logout() {
      localStorage.removeItem('xh')
      window.location.reload()
      this.$router.push('/')
    },
  },
  watch: {
    screenWidth() {
      if (this.windowWidth > 767.5) {
        this.drawer = false
      }
    }
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
</script>

<style scoped>
.header, .view {
  margin: 0 auto;
  width: 100%;
}

.view {
  min-height: calc(100vh - 100px);
}

::v-deep .el-header {
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.login {
  cursor: default;
  height: 40px;
  margin: 10px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.login a {
  margin: 0 5px;
  color: #333;
  text-decoration: none;
}

.login a:hover {
  color: #409EFF;
}

.el-menu {
  border-right: none;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

::v-deep .el-drawer {
  width: 240px !important;
  user-select: none;
}

::v-deep .el-drawer__header {
  padding: 20px 10px 0 15px;
  margin-bottom: 15px;
}

.search-bar {
  padding: 0 10px;
  min-width: 120px;
  max-width: 235px;
  height: 60px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
}

::v-deep .search-bar .el-input__inner {
  border-right: none;
}

::v-deep .search-bar .el-input-group__append {
  padding: 0 15px;
  background: #409EFF;
  color: #fff;
  border-color: #409EFF;
}

@media screen and (min-width: 767.5px) {
  .hidden-sm-and-up {
    display: none !important;
  }

}

@media screen and (max-width: 767.5px) {
  .el-header {
    height: 60px !important;
  }

  .header, .view {
    max-width: 720px;
    height: auto !important;
  }

  .view {
    min-height: calc(100vh - 80px);
  }

  .header img {
    height: 60px;
  }

  .hidden-xs-only {
    display: none !important;
  }

  .login {
    margin: 10px 2px;
  }

  .el-dropdown-selfdefine {
    margin: 10px 0 !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .header, .view {
    max-width: 960px;
  }

  .header img {
    height: 80px;
  }

  .hidden-xs-only .el-dropdown-menu__item, .el-menu-item {
    padding: 0 15px;
  }

  ::v-deep .search-bar .el-input__inner {
    width: 130px !important;
  }

  .search-bar {
    right: -35px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .header, .view {
    max-width: 1140px;
  }

  .header img {
    height: 80px;
  }

  ::v-deep .search-bar .el-input__inner {
    width: 160px !important;
  }
}

@media screen and (min-width: 1200px) {
  .header, .view {
    max-width: 1140px;
  }

  .header img {
    height: 80px;
  }

  ::v-deep .search-bar .el-input__inner {
    width: 180px !important;
  }
}

::v-deep.el-main {
  background-color: #F6F6F6;
  color: #333;
  padding: 10px 5px;
  overflow: hidden;
}
</style>