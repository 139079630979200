<template>
  <div>
    <div class="people" v-if="!isnotfound">
      <div class="user-profile">
        <div class="user-profile-bgimg">
          <img :src="userInfo.bgImage" alt="" v-if="!loading"/>
          <el-upload
              class="avatar-uploader"
              :action="this.$baseUrl+'/file/upload'"
              :headers="{token:user.token}"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-button size="small" icon="el-icon-camera-solid" v-if="user.id==userInfo.id">更换背景</el-button>
          </el-upload>
          <el-skeleton style="width: 100%; height: 100%;" :loading="loading" animated>
            <template slot="template">
              <el-skeleton-item variant="image"/>
            </template>
          </el-skeleton>
        </div>
        <div class="user-profile-info">
          <div class="user-profile-info-avatar">
            <div class="user-profile-avatar">
              <img :src="userInfo.avatar" alt="" v-if="!loading"/>
              <el-skeleton style="width: 100%;height: 100%;" :loading="loading" animated>
                <template slot="template">
                  <el-skeleton-item variant="image"/>
                </template>
              </el-skeleton>
            </div>
          </div>
          <div class="user-profile-info-box">
            <div class="user-profile-name">
              <span>{{ userInfo.nickname }}</span>
              <div style="display: flex;align-items: center;margin: 0;">
                <p>
                  IP：{{ userInfo.ipAttr != null ? userInfo.ipAttr : '未知' }}
                </p>

                <el-button v-if="user.id==userInfo.id" @click.native="$router.push({name:'Settings'})">
                  <i class="el-icon-jichushezhi"></i>
                </el-button>
              </div>
            </div>
            <div class="user-profile-data">
              <p>文章数<span class="user-profile-data-num">{{ articledata.countAll }}</span></p>
            </div>
            <div class="user-profile-desc" v-if="userInfo.userProfile!=null && userInfo.userProfile!=''">
              <span>个人简介： {{ userInfo.userProfile }}</span>
            </div>
            <div class="user-profile-other">
              <div class="user-profile-desc" v-if="userInfo.blogProfile!=null && userInfo.blogProfile!=''">
                <span>博客简介： {{ userInfo.blogProfile }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-row :gutter="10">
        <el-col :md="18" :sm="17" :xs="24">
          <el-card :body-style="{ padding: '10px' }" style="margin: 10px 0;">
            <el-menu
                router
                mode="horizontal"
                text-color="rgba(0, 0, 0)"
                active-text-color="#1687ff"
                :default-active="$route.path"
                style="border: none">
              <el-menu-item :index="'/people/'+userInfo.username">
                <span slot="title">文章</span>
              </el-menu-item>
            </el-menu>
            <el-main class="content">
              <router-view/>
            </el-main>
          </el-card>
        </el-col>
        <el-col :md="6" :sm="7" :xs="24" class="aside" v-if="windowWidth>=768">
          <Creator v-if="user.id==userInfo.id"/>
          <el-card class="user-profile-card" :body-style="{ padding: '10px 20px' }">
            <div slot="header" class="clearfix">个人成就</div>
            <div>
              <p><span>加入于</span><span>{{ formatDate(userInfo.createTime) }}</span></p>
              <p><span>文章访总问量</span>{{ articledata.countView }}</p>
            </div>
          </el-card>
          <copyright/>
        </el-col>
      </el-row>
    </div>
    <Page404 v-else></Page404>
  </div>
</template>

<script>
import Page404 from "@/views/codeView/Page-404.vue";
import Creator from "@/components/home/creator.vue";
import copyright from "@/components/copyright.vue";

export default {
  name: "people",
  components: {copyright, Creator, Page404},
  data() {
    return {
      user: {},
      userInfo: {},
      articledata: {
        countAll: null,
        countView: null
      },
      loading: true,
      windowWidth: 0,
      isnotfound: false
    }
  },
  mounted() {
    this.getUser(this.$route.params.username)
    this.user = localStorage.getItem('xh') ? JSON.parse(localStorage.getItem('xh')) : {}
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    getUser(username) {
      if (username == undefined) return
      this.$request.get('/user/getByUsername/' + username).then(res => {
        if (res.code == '200') {
          this.userInfo = res.data
          this.getArticleCount(this.userInfo.id)
          this.getArticleViewCount(this.userInfo.id)
          document.title = this.userInfo.nickname + ' - XiaohuaBlog'
          this.loading = false
        } else if (res.code == '404') {
          this.isnotfound = true
        }
      })
    },
    getArticleCount(id) {
      this.$request.get('/article/count/' + id + '/2').then(res => {
        if (res.code == '200') {
          this.articledata.countAll = res.data
        }
      })
    },
    getArticleViewCount(id) {
      this.$request.get('/article/countView/' + id + '/2').then(res => {
        if (res.code == '200') {
          this.articledata.countView = res.data
        }
      })
    },
    update() {
      // 更新用户信息
      this.$request.put('/user/update', this.user).then(res => {
        if (res.code === '200') {
          this.$message.success('更新成功')
          // 更新sessionStorage
          localStorage.setItem('xh', JSON.stringify(this.user))
          //console.log(sessionStorage.getItem('honey-user'))
          //通过eventBus实现组件之间的通信
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleAvatarSuccess(response, file, fileList) {
      // 把user的头像属性换成上传的图片的链接
      this.user.bgImage = response.data
      this.userInfo.bgImage = response.data
      this.update()
    },
    beforeAvatarUpload(file) {
      const isJPG =
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isJPG) {
        this.$message.error('上传图片只能是jpg、png、jpeg格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      return isJPG && isLt5M;
    },
    formatDate(time) {
      return this.$moment(time == null ? '' : time).format('YYYY-MM-DD');
    }
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  }
}
</script>

<style scoped>

.user-profile {
  background: #fff;
}

::v-deep .el-skeleton {
  width: 100%;
  height: 100%;

}

::v-deep .el-skeleton__image {
  width: 100%;
  height: 100%;
}

.user-profile .user-profile-bgimg {
  width: 100%;
  height: 168px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  position: relative;
}

.user-profile .user-profile-bgimg .avatar-uploader {
  position: absolute;
  top: 10px;
  right: 10px;
}

.user-profile .user-profile-bgimg .avatar-uploader .el-button {
  background: transparent;
  color: #8491a5;
  border: 1px solid #8491a5;
  border-radius: 5px;
}

.user-profile .user-profile-bgimg .avatar-uploader .el-button:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.user-profile .user-profile-bgimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile .user-profile-info {
  width: 100%;
  height: fit-content;
  display: flex;
}

.user-profile .user-profile-info-avatar {
  width: 175px;
  margin-right: 20px;
  position: relative;
}

.user-profile .user-profile-info-avatar .user-profile-avatar {
  width: 175px;
  height: 175px;
  border: 4px solid #f1f2f3;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -80px;
  left: 20px;
  transition: all 0.5s ease;
}

.user-profile .user-profile-info-avatar img {
  width: 100%;
  height: 100%;
}

.user-profile .user-profile-info-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 5px 10px;
}

.user-profile .user-profile-info-box div {
  margin-bottom: 10px;
}

.user-profile .user-profile-info-box .user-profile-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile .user-profile-info-box .user-profile-name span {
  font-size: 24px;
  font-weight: 400;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.user-profile .user-profile-info-box .user-profile-name p {
  font-size: 14px;
  color: #8c939d;
  margin-right: 20px;
  vertical-align: middle;
}

.user-profile .user-profile-info-box .user-profile-name p i {
  font-size: 15px;
}

::v-deep.user-profile .user-profile-info-box .user-profile-name .el-button {
  color: #409eff;
  border: none;
  padding: 5px;
  font-size: 20px;
}

.user-profile .user-profile-info-box .user-profile-data {
  font-size: 15px;
  color: #6e6e6e;
}

.user-profile .user-profile-info-box .user-profile-data .user-profile-data-num {
  font-size: 20px;
  font-weight: 600;
  color: #10131a;
  margin: 0 5px;
}

.user-profile .user-profile-info-box .user-profile-desc {
  font-size: 14px;
  color: #6e6e6e;
}

.el-main {
  padding: 0;
  overflow: visible;
}

.aside {
  padding-top: 10px;
  display: grid;
  grid-gap: 10px;
}

.user-profile-card div p {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.content {
  padding-top: 0.9rem;
  background-color: #FFFFFF;
}

@media (max-width: 768px) {
  .user-profile .user-profile-bgimg {
    height: 128px;
  }

  .user-profile .user-profile-info-avatar,
  .user-profile .user-profile-info-avatar .user-profile-avatar {
    width: 150px;
  }

  .user-profile .user-profile-info-avatar .user-profile-avatar {
    height: 150px;
    top: -65px;
  }

  .user-profile .user-profile-info-box .user-profile-name span {
    max-width: 140px;
  }

  .user-profile .user-profile-info-box .user-profile-name p {
    margin: 0 5px;
  }
}

@media (max-width: 576px) {
  .user-profile .user-profile-bgimg {
    height: 100px;
  }

  .user-profile .user-profile-info-avatar,
  .user-profile .user-profile-info-avatar .user-profile-avatar {
    width: 0;
    height: 0;
    margin: 0;
  }

  .user-profile .user-profile-info-avatar .user-profile-avatar {
    width: 75px;
    height: 75px;
    top: -35px;
  }

  .user-profile .user-profile-info-box .user-profile-name {
    padding-left: 80px;
  }

  .user-profile .user-profile-info-box .user-profile-name span {
    max-width: 80px;
  }

  .user-profile .user-profile-info-box .user-profile-name p {
    margin: 0;
  }
}

</style>